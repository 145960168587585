import { useSelector } from 'react-redux';
import { selectPlatform } from '../../lib/reducers/appSlice';
import { ShipTabProps } from '../../screens/ShipScreen';
import { VehicleSelect } from './VehicleClassSelect';
import { DeliverProDriverCard } from '../deliverPro/DeliverProDriverCard';
import StopsRepeater from './StopsRepeater';
import { Form } from 'formik';
import MatchScheduler from './MatchScheduler';
import AccessorialOptions from './AccessorialOptions';
import { Label } from '@blueprintjs/core';
import StepFooter from './StepFooter';
import EstimateBanner from './EstimateBanner';
import { PreferredDriverSelect } from './preferredDriver/PreferredDriverSelect';
import { EstimateValues } from './steps/Estimate';
import EstimateFormOnChange from './EstimateFormOnChange';
import { FormikChangeHelpers } from '../form/FormikOnChange';
import { selectFeatureFlag } from '../../lib/reducers/userSlice';
import {
  isMatchEstimate,
  isMatchFullyEditable,
  nextLabel,
} from '../../lib/Utility';
import { selectEstimateMatch } from '../../lib/reducers/estimateSlice';

type EstimateFormProps = {
  handleSubmit: (
    values: EstimateValues,
    actions: FormikChangeHelpers<EstimateValues>
  ) => Promise<void>;
  csvPreferredDriverId: string | null | undefined;
} & ShipTabProps;

export default function EstimateForm({
  showSideBar,
  changeTab,
  handleSubmit,
  csvPreferredDriverId,
}: EstimateFormProps) {
  const hasPreferredDriverFeature = useSelector(
    selectFeatureFlag('preferred_driver')
  );
  const hasAccessorialsFeature = useSelector(selectFeatureFlag('accessorials'));
  const isDeliverPro = useSelector(selectPlatform) === 'deliver_pro';
  const match = useSelector(selectEstimateMatch);
  const isEstimate = isMatchEstimate(match);
  const isFullyEditable = isMatchFullyEditable(match);

  return (
    <Form>
      <EstimateFormOnChange
        onChange={(values: EstimateValues, _meta, actions) =>
          handleSubmit(values, actions)
        }
      />
      <StopsRepeater showSideBar={showSideBar} />

      {isDeliverPro ? (
        <DeliverProDriverCard />
      ) : (
        <VehicleSelect disabled={!isFullyEditable} />
      )}

      <div className='panelDivider' />

      <MatchScheduler />

      {hasAccessorialsFeature && (
        <>
          <div className='panelDivider' />
          <Label className='u-pad__top--lg sectionLabelDirect sectionLabelExtraPadding'>
            OPTIONAL SERVICES
          </Label>
          <AccessorialOptions disabled={!isFullyEditable} />
        </>
      )}

      {isEstimate && !isDeliverPro && hasPreferredDriverFeature && (
        <PreferredDriverSelect csvPreferredDriverId={csvPreferredDriverId} />
      )}

      <StepFooter onNext={() => changeTab(1)} nextLabel={nextLabel(isEstimate)}>
        <EstimateBanner />
      </StepFooter>
    </Form>
  );
}
