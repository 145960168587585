import { VehicleClass } from '../../../lib/actions/MatchAction';

type Props = {
  vehicleClass: VehicleClass;
};

export default function MatchVehicleCards({ vehicleClass }: Props) {
  return (
    <>
      <div className='cardThreeColumn'>
        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>VEHICLE TYPE</p>
            <h2>{vehicleClass.type.name}</h2>
          </div>
        </div>

        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>VEHICLE CLASS</p>
            <h2>{vehicleClass.name}</h2>
          </div>
        </div>
      </div>
    </>
  );
}
