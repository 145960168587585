import DeliverProSignUpForm from './DeliverProSignUpForm';

export default function DeliverProSignUp() {
  return (
    <div>
      <div className='actionForm' style={{ paddingTop: 15 }}>
        <div>
          <img
            className='centerImage'
            src='/img/deliver-pro.png'
            alt='deliver pro'
            style={{ padding: 30 }}
          />
          <h1 className='loginHeader'>Sign Up</h1>
          <p className='loginSubtitle'>For your driver: </p>
          <DeliverProSignUpForm />
        </div>
      </div>
    </div>
  );
}
