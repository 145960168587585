import { MatchStop } from '../../lib/actions/MatchAction';

type Props = {
  stop: MatchStop;
};

export default function TrackingLink({ stop }: Props) {
  return stop.tracking_url ? (
    <p>
      <a href={stop.tracking_url} target='_blank' rel='noreferrer'>
        Tracking link
      </a>
    </p>
  ) : null;
}
