import { Grid, Row, Col } from 'react-flexbox-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export function ChoosePlatformScreen() {
  const navigate = useNavigate();

  function fraytCard() {
    return (
      <div className='platformCard'>
        <div>
          <img src='/img/frayt.svg' alt='FRAYT'></img>
          <text className='titleText'>Marketplace</text>
        </div>
        <text className='bodyText'>
          Place delivery orders for all FRAYT drivers. Flexible capacity and
          nationwide coverage.
        </text>
        <button
          onClick={() => navigate('/ship')}
          className='bp4-button bp4-fill bp4-large primaryButtonFilled u-push__top--sm'
        >
          Use Marketplace
        </button>
      </div>
    );
  }

  function deliverProCard() {
    return (
      <div className='platformCard'>
        <div>
          <img src='/img/deliver-pro.svg' alt='Deliver Pro'></img>
        </div>
        <text className='bodyText'>
          Get direct access to your Deliver Pro driver. Place deliveries with
          custom pricing.
        </text>
        <button
          onClick={() => navigate('/deliverpro/ship')}
          className='bp4-button bp4-fill bp4-large deliverProButtonFilled u-push__top--sm'
        >
          Use Deliver Pro
        </button>
      </div>
    );
  }

  return (
    <div className='appContent'>
      <Grid>
        <Row center='xs'>
          <Col>
            <div className='backgroundCircle'>
              <FontAwesomeIcon className='icon' icon='truck' />
            </div>
          </Col>
        </Row>
        <Row center='xs' className='welcomeText'>
          <Col xs={6}>
            You have access to both the FRAYT Marketplace and Deliver Pro.
          </Col>
        </Row>
        <Row center='xs'>
          <Col>{fraytCard()}</Col>
          <Col>{deliverProCard()}</Col>
        </Row>
      </Grid>
    </div>
  );
}
