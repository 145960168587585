import {
  createSelector,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '.';
import { Match } from '../actions/MatchAction';
import * as MatchAction from '../actions/MatchAction';
import {
  MatchResponseData,
  MatchData,
  NewMatchData,
} from '../actions/MatchAction';
import { createAxiosThunk, ThunkStatus } from '../AxiosThunk';
import { getErrorLevel, getErrorMessage } from '../FraytRequest';
import { isMatchResponseDataAction } from './matchesSlice';
import { isReducerAction } from './helpers';
import * as UserAction from '../actions/UserAction';
import { Accessorial } from '../actions/AccessorialAction';
import * as AccessorialAction from '../actions/AccessorialAction';
import { PreferredDriverOption } from '../../components/estimate/preferredDriver/PreferredDriverSelect';
import * as VehicleAction from '../actions/VehicleAction';
import { VehicleTypeWithClasses } from '../actions/VehicleAction';

export type EstimateError = {
  level?: 'danger' | 'warning';
  message: null | string;
};

export type EstimateState = {
  match: null | Match;
  status: ThunkStatus;
  errors: EstimateError;
  accessorials: Accessorial[];
  vehicleTypes: VehicleTypeWithClasses[];
  boxTruckAgreement: boolean;
  // keep locally which option this driver was chosen from
  preferredDriverType: PreferredDriverOption;
};

const initialState: EstimateState = {
  match: null,
  status: 'idle',
  errors: { level: 'danger', message: null },
  accessorials: [],
  vehicleTypes: [],
  boxTruckAgreement: false,
  preferredDriverType: PreferredDriverOption.None,
};

export const createEstimate = createAxiosThunk<MatchResponseData, NewMatchData>(
  'estimate/createEstimate',
  MatchAction.createMatch
);

export const duplicateMatch = createAxiosThunk<
  MatchResponseData,
  [string, MatchData]
>('estimate/duplicateMatch', ([matchId, data]) =>
  MatchAction.duplicateMatch(matchId, data)
);

export const updateEstimate = createAxiosThunk<
  MatchResponseData,
  [string, MatchData]
>('estimate/updateEstimate', ([matchId, data]) =>
  MatchAction.updateMatch(matchId, data)
);

export const authorizeEstimate = createAxiosThunk<MatchResponseData, string>(
  'estimate/authorizeEstimate',
  MatchAction.authorizeMatch
);

export const fetchEstimate = createAxiosThunk<MatchResponseData, string>(
  'estimate/fetchEstimate',
  MatchAction.getMatch
);

export const fetchAccessorials =
  createAxiosThunk<AccessorialAction.AccessorialsResponseData>(
    'estimate/fetchAccessorials',
    AccessorialAction.getAccessorials
  );

export const fetchVehicleTypes =
  createAxiosThunk<VehicleAction.VehicleTypesResponseData>(
    'estimate/fetchVehicleTypes',
    VehicleAction.getVehicleTypes
  );

/**
 * Takes a UserAction, but we have to do this to handle errors correctly
 */
export const getPreferredDriver = createAxiosThunk<
  UserAction.PreferredDriverResponseData,
  UserAction.PreferredDriverParams
>('estimate/getPreferredDriver', UserAction.getPreferredDriverOptionsBy);

const reset = (state: EstimateState) => {
  state.boxTruckAgreement = false;
  state.status = 'idle';
  state.errors = { level: 'danger', message: null };
  state.match = null;
};

const reducerName = 'estimate';

const isEstimatePending = (action: PayloadAction<unknown>) =>
  isReducerAction(reducerName)(action) && isPending(action);

const isEstimateRejected = (action: PayloadAction<unknown>) =>
  isReducerAction(reducerName)(action) && isRejected(action);

const isEstimateSuccess = (action: PayloadAction<unknown>) =>
  isReducerAction(reducerName)(action) &&
  isFulfilled(action) &&
  isMatchResponseDataAction(action) &&
  !action.type.endsWith('authorizeEstimate');

const estimateSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetEstimate: reset,
    setBoxTruckAgreement: (state, action: PayloadAction<boolean>) => {
      state.boxTruckAgreement = action.payload;
    },
    setPreferredDriverType: (state, action: PayloadAction<number>) => {
      state.preferredDriverType = action.payload;
    },
    setPreferredDriver: (
      state,
      action: PayloadAction<MatchAction.Driver | undefined>
    ) => {
      if (state.match) state.match.preferred_driver = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authorizeEstimate.fulfilled, reset)
      .addCase(getPreferredDriver.fulfilled, (state, action) => {
        // this endpoint returns an empty array if no matching emails are found
        const preferredDriver = action.payload.data[0];
        if (preferredDriver && state.match) {
          state.match = {
            ...state.match,
            preferred_driver: preferredDriver,
          };
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.errors = {
            level: 'warning',
            message: 'Preferred Driver email is invalid',
          };
        }
      })
      .addCase(fetchAccessorials.fulfilled, (state, action) => {
        state.accessorials = action.payload.response;
        state.status = 'succeeded';
      })
      .addCase(fetchVehicleTypes.fulfilled, (state, action) => {
        state.vehicleTypes = action.payload.sort((a, b) => a.index - b.index);
        state.status = 'succeeded';
      })
      .addMatcher(isEstimatePending, state => {
        state.status = 'loading';
      })
      .addMatcher(isEstimateRejected, (state, action) => {
        state.status = 'failed';

        if (!action.payload) {
          state.errors = {
            message: 'Failed to connect to server',
            level: 'danger',
          };
          return;
        }

        state.errors = {
          message: getErrorMessage(action.payload),
          level: getErrorLevel(action.payload),
        };
      })
      .addMatcher(
        isEstimateSuccess,
        (
          state: EstimateState,
          action: PayloadAction<MatchResponseData, string>
        ) => {
          state.errors = {
            level: 'danger',
            message: null,
          };
          state.status = 'succeeded';
          state.match = action.payload.response;
        }
      );
  },
});

export default estimateSlice.reducer;

export const {
  resetEstimate,
  setBoxTruckAgreement,
  setPreferredDriverType,
  setPreferredDriver,
} = estimateSlice.actions;

export const selectEstimateMatch = (state: RootState) => state.estimate.match;
export const selectEstimateStatus = (state: RootState) => state.estimate.status;
export const selectEstimateErrors = (state: RootState) => state.estimate.errors;
export const selectEstimateAccessorials = (state: RootState) =>
  state.estimate.accessorials;

export const selectVehicleTypes = (state: RootState) =>
  state.estimate.vehicleTypes;

type VehicleClass = VehicleAction.VehicleClass & MatchAction.VehicleClass;

export const selectVehicleClass = createSelector(
  [
    selectVehicleTypes,
    (_state: RootState, vehicleClassId: string | null | undefined) =>
      vehicleClassId,
  ],
  (vehicleTypes, vehicleClassId): VehicleClass | undefined => {
    if (!vehicleClassId) return;

    for (const { classes, ...vehicleType } of vehicleTypes) {
      const vc = classes.find(c => c.id === vehicleClassId);

      if (vc) return { ...vc, type: vehicleType };
    }
  }
);
export const selectEstimateBoxTruckAgreement = (state: RootState) =>
  state.estimate.boxTruckAgreement;
export const selectPreferredDriverType = (state: RootState) =>
  state.estimate.preferredDriverType;
export const selectPreferredDriver = (state: RootState) =>
  state.estimate.match?.preferred_driver;
