import { Col, Row } from 'react-flexbox-grid';
import { selectUser } from '../../lib/reducers/userSlice';
import { useAppSelector } from '../../lib/store';
import { formatVehicleImage } from '../../lib/Utility';

export function DeliverProDriverCard() {
  const driver = useAppSelector(selectUser)?.default_driver;

  if (!driver) return null;

  const { vehicle } = driver;

  return (
    <Row>
      <Col md={12} lg={6}>
        <div className='deliverProDriverCard'>
          <div className='deliverProDriver'>
            <text className='driverTitle'>YOUR DRIVER</text>
            <text className='driverName'>{`${driver.first_name} ${driver.last_name}`}</text>
            {
              // FIXME: driver address is missing from referring driver
              // update: we no longer reference referring driver for matches, only default driver
            }
            <text className='driverDetails'>{`{insert driver address here}`}</text>
            <text className='driverDetails'>{`${vehicle.vehicle_year} ${vehicle.vehicle_make} ${vehicle.vehicle_model}`}</text>
          </div>
          <div className='divider'></div>
          <div className='deliverProVehicle'>
            <div className='cardImage'>
              <img
                className='deliverProVehicleImage'
                src={formatVehicleImage(driver.vehicle.vehicle_class.type.key)}
                alt='Frayt'
              />
            </div>
            <text className='vehicleText'>{vehicle.vehicle_class.name}</text>
          </div>
        </div>
      </Col>
    </Row>
  );
}
