import { DeliverProBadgeSVG } from '../../assets/svg/deliver-pro-badge-svg';
import { Driver } from '../../lib/actions/MatchAction';

export const DeliverProBadge = ({ driver }: { driver: Driver }) => {
  return driver.platforms.includes('deliver_pro') ? (
    <div className='deliverProBadge'>
      <DeliverProBadgeSVG fill='#0066FF' />
    </div>
  ) : null;
};
