import { InfoWindow, Marker } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import { Driver, Match } from '../../lib/actions/MatchAction';
import Map from '../Map';

type DriverMarkerProps = {
  driver: Driver | null;
};

function DriverMarker({ driver }: DriverMarkerProps) {
  const [isOpen, setIsOpen] = useState(false);

  if (driver?.current_location) {
    const { first_name, last_name, current_location } = driver;

    return (
      <Marker
        position={current_location}
        // label={"D"}
        icon={{
          url: '/img/icons/location.svg',
          size: new google.maps.Size(28, 28),
          anchor: new google.maps.Point(14, 14),
        }}
        onClick={() => setIsOpen(true)}
      >
        {isOpen && (
          <InfoWindow onCloseClick={() => setIsOpen(false)}>
            <span>
              <b>Driver:</b> {first_name} {last_name}
            </span>
          </InfoWindow>
        )}
      </Marker>
    );
  }

  return null;
}

export type MatchMapProps = {
  match: Match;
  isEnRoute: boolean;
};

export default function MatchMap({ match, isEnRoute }: MatchMapProps) {
  const { origin_address, stops, driver } = match;
  const [map, setMap] = useState<google.maps.Map>();
  const [origin_latlng] = useState<google.maps.LatLng>(
    new google.maps.LatLng(origin_address.lat, origin_address.lng)
  );
  const [destinations_latlng] = useState<google.maps.LatLng[]>(
    stops.map(
      stop =>
        new google.maps.LatLng(
          stop.destination_address.lat,
          stop.destination_address.lng
        )
    )
  );

  const setBounds = useCallback(() => {
    if (map) {
      const drv_loc = driver?.current_location;
      const bounds = new google.maps.LatLngBounds(drv_loc?.id ? drv_loc : null);
      const origin_bounds = new google.maps.LatLngBounds(origin_latlng);

      destinations_latlng.forEach(destination => {
        bounds.union(new google.maps.LatLngBounds(destination));
      });

      bounds.union(origin_bounds);

      map.fitBounds(bounds);
    }
  }, [origin_latlng, destinations_latlng, driver?.current_location, map]);

  useEffect(() => {
    setBounds();
  }, [setBounds]);

  const getMarkers = () => {
    const markers = [] as React.ReactNode[];

    markers.push(
      <Marker position={origin_latlng} label={{ text: 'P', color: '#fff' }} />
    );

    destinations_latlng.forEach((destination, index) =>
      markers.push(
        <Marker
          position={destination}
          key={index}
          label={{ text: `${index + 1}`, color: '#fff' }}
        />
      )
    );

    if (isEnRoute)
      markers.push(<DriverMarker key={0} driver={match?.driver} />);

    return markers;
  };

  return <Map onLoad={setMap} directions={null} markers={getMarkers()} />;
}
