import { Callout } from '@blueprintjs/core';

type ErrorCalloutProps = {
  message: string | null;
  level?: 'danger' | 'warning';
  title?: string | null;
};

export default function ErrorCallout({
  message,
  level,
  title,
}: ErrorCalloutProps) {
  return message ? (
    <Callout
      intent={level ?? 'warning'}
      className={level === 'danger' ? 'calloutError' : ''}
      style={{ marginBottom: 20 }}
      title={title ?? 'We encountered a problem'}
    >
      {message}
    </Callout>
  ) : null;
}
