import { Button, Icon, Radio, RadioGroup } from '@blueprintjs/core';
import { useAppDispatch, useAppSelector } from '../../../lib/store';
import {
  Driver,
  Match,
  PreferredDriverMatchData,
} from '../../../lib/actions/MatchAction';
import { updateMatch } from '../../../lib/reducers/matchesSlice';
import { useState } from 'react';
import PreferredDriverSelectByEmail from '../preferredDriver/PreferredDriverSelectByEmail';
import { Row } from 'react-flexbox-grid';
import PreferredDriverSelectByName from '../preferredDriver/PreferredDriverSelectByName';
import { DeliverProBadge } from '../../deliverPro/DeliverProBadge';
import { DriverDataCard } from '../preferredDriver/DriverDataCard';
import { selectUser } from '../../../lib/reducers/userSlice';

enum NewDriverPanelOptions {
  RecentDrivers = 'Recent Drivers',
  Email = 'Email',
}

export default function PreferredDriverPanel({ match }: { match: Match }) {
  const dispatch = useAppDispatch();
  const [dirty, setDirty] = useState<boolean>();
  const [selectedPreferredDriver, setSelectedPreferredDriver] = useState<
    Driver | undefined
  >(match.preferred_driver);
  const [newDriverPanelRadio, setNewDriverPanelRadio] =
    useState<NewDriverPanelOptions>(NewDriverPanelOptions.RecentDrivers);
  const [panelToShow, setPanelToShow] = useState<'actions' | 'new_driver'>(
    'actions'
  );
  const shipper = useAppSelector(selectUser);
  const defaultDriver = shipper?.default_driver;

  const updatePreferredDriver = async (
    preferredDriver: Driver | undefined,
    platform: Match['platform'] | undefined = undefined
  ) => {
    setSelectedPreferredDriver(preferredDriver);
    setDirty(true);
    const data: PreferredDriverMatchData = {
      preferred_driver_id: preferredDriver?.id || null,
      platform: platform || match.platform,
    };
    await dispatch(updateMatch([match.id, data]));
  };

  const renderStatusMessage = () => {
    if (
      match.state === 'offered' &&
      match.platform === 'preferred_driver' &&
      match.preferred_driver &&
      selectedPreferredDriver
    ) {
      return (
        <h2>
          <div style={{ display: 'inline' }}>
            {`${match.preferred_driver.first_name || 'A driver'} ${
              match.preferred_driver.last_name || ''
            } `}
          </div>
          <div
            style={{
              display: 'inline-flex',
              position: 'relative',
              top: '2px',
            }}
          >
            <DeliverProBadge driver={match.preferred_driver} />
          </div>
          <div style={{ display: 'inline' }}>
            has been sent a notification for this order.
          </div>
        </h2>
      );
    } else if (!match.preferred_driver) {
      return (
        <h2>Your preferred driver is not available. What do you want to do?</h2>
      );
    } else if (match.state === 'accepted') {
      return <h2>Your chosen driver has accepted the order.</h2>;
    } else {
      return null;
    }
  };

  const renderStatus = () => (
    <div className='cardInset'>
      <div className='cardInset--icon'>
        <Icon icon='person' iconSize={20} />
      </div>
      <div className='cardInset--data'>
        <p className='statusDescription'>PREFERRED DRIVER</p>
        {match.origin_address.name && <h2>{match.origin_address.name}</h2>}
        {renderStatusMessage()}
      </div>
    </div>
  );

  const renderActionsPanel = () => (
    <div className='cardOption'>
      <Button
        className='cardInset--button'
        intent='primary'
        large
        onClick={() => setPanelToShow('new_driver')}
        text='New Driver'
      />
      <Button
        className='cardInset--button'
        intent='primary'
        large
        onClick={() => updatePreferredDriver(undefined, 'marketplace')}
      >
        Any Driver
      </Button>
    </div>
  );

  const renderNewDriverPanel = () => (
    <div>
      <div className='cardInset driver-options-container'>
        <Row>
          <RadioGroup
            inline
            onChange={event => {
              setNewDriverPanelRadio(
                event.currentTarget.value as NewDriverPanelOptions
              );
            }}
            selectedValue={newDriverPanelRadio}
          >
            <Radio
              label={'Recent Drivers'}
              type='checkbox'
              value={NewDriverPanelOptions.RecentDrivers}
              className='cardTitle'
            />
            <Radio
              label={'Email'}
              type='checkbox'
              value={NewDriverPanelOptions.Email}
              className='cardTitle'
            />
          </RadioGroup>
        </Row>
      </div>
      {newDriverPanelRadio === NewDriverPanelOptions.RecentDrivers && (
        <PreferredDriverSelectByName
          defaultDriverId={defaultDriver ? defaultDriver.id : undefined}
          currentPreferredDriver={
            dirty && selectedPreferredDriver
              ? selectedPreferredDriver
              : undefined
          }
          minVehicleClass={match.vehicle_class}
          onSelect={selectedDriver => {
            updatePreferredDriver(selectedDriver);
          }}
          padding={false}
          currentMatch={match}
        />
      )}
      {newDriverPanelRadio === NewDriverPanelOptions.Email && (
        <PreferredDriverSelectByEmail
          defaultDriverId={defaultDriver ? defaultDriver.id : undefined}
          currentPreferredDriver={
            dirty && selectedPreferredDriver
              ? selectedPreferredDriver
              : undefined
          }
          minVehicleClass={match.vehicle_class}
          onSelect={selectedDriver => {
            updatePreferredDriver(selectedDriver);
          }}
          padding={false}
          match={match}
        />
      )}
      {selectedPreferredDriver && (
        <DriverDataCard driver={selectedPreferredDriver} minimal panel />
      )}
      <Button
        onClick={() => setPanelToShow('actions')}
        icon='chevron-left'
        style={{ marginTop: '10px' }}
        fill
      >
        Back
      </Button>
    </div>
  );

  const renderPanel = (panelToShow: 'actions' | 'new_driver') => (
    <div className='cardInset driver-options-container'>
      {panelToShow === 'actions' && renderActionsPanel()}

      {panelToShow === 'new_driver' && renderNewDriverPanel()}
    </div>
  );

  return match.platform === 'deliver_pro' ||
    match.platform === 'preferred_driver' ? (
    <div className='preferred-container'>
      {renderStatus()}
      {(match.state === 'offered' ||
        match.state === 'assigning_driver' ||
        match.state === 'scheduled' ||
        match.state === 'offer_not_accepted') &&
        renderPanel(panelToShow)}
    </div>
  ) : (
    <></>
  );
}
