import { useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Button, FormGroup, HTMLSelect, TextArea } from '@blueprintjs/core';
import { Row, Col } from 'react-flexbox-grid';
import { useAppDispatch } from '../../lib/store';
import { cancelMatch } from '../../lib/reducers/matchesSlice';
import { Match } from '../../lib/actions/MatchAction';
import { getErrorMessage } from '../../lib/FraytRequest';

const reasonOptions = [
  { value: '', label: '- Select Reason -' },
  { value: 'MISTAKENLY_PLACED', label: 'Mistakenly placed' },
  {
    value: 'RESCHEDULING',
    label: 'Rescheduling',
    subOptions: [
      { value: 'OUT_OF_STOCK', label: 'Out of stock' },
      { value: 'CUSTOMER_CHANGED_DATE', label: 'Customer changed date' },
      { value: 'WHEN_DRIVER_AVAILABLE', label: 'When driver is available' },
    ],
  },
  {
    value: 'FOUND_ANOTHER_SERVICE',
    label: 'Found another service',
    subOptions: [
      { value: 'FASTER', label: 'Faster' },
      { value: 'CHEAPER', label: 'Cheaper' },
      { value: 'BETTER_SERVICE', label: 'Better service' },
    ],
  },
  {
    value: 'SERVICE_FAILURE',
    label: 'Service failure',
    subOptions: [
      { value: 'TOOK_TOO_LONG_TO_ASSIGN', label: 'Took too long to assign' },
      { value: 'NO_CALL_NO_SHOW', label: 'No call no show' },
      { value: 'COULDNT_CONTACT_DRIVER', label: "Couldn't contact driver" },
      { value: 'LATE_TIMING', label: 'Late/timing' },
    ],
  },
  { value: 'SELF_DELIVERED', label: 'Self delivered' },
  { value: 'CUSTOMER_CANCELED', label: 'Customer canceled' },
  { value: 'OUT_OF_STOCK', label: 'Out of stock' },
  { value: 'TEST_MATCH', label: 'Test Match/Getting quote' },
];

type CancelValues = {
  reason: string;
  companyName: string;
  subReason?: string;
};

type CancelMatchProps = {
  close: () => void;
  match: Match;
};

export default function CancelMatchForm({ match, close }: CancelMatchProps) {
  const [error, setError] = useState<string | null>(null);
  const [selectedReason, setSelectedReason] = useState('');

  const dispatch = useAppDispatch();

  const handleFormSubmit = async (
    values: CancelValues,
    actions: FormikHelpers<CancelValues>
  ) => {
    setError(null);
    let reason = values.reason;
    const selectedOption = reasonOptions.find(
      option => option.value === reason
    );
    const selectedSubReason = selectedOption?.subOptions?.find(
      subOption => subOption.value === values.subReason
    );
    let message = selectedOption?.label || '';

    if (selectedSubReason) {
      reason = `${reason}_${selectedSubReason?.value}`;
      if (
        selectedOption?.value === 'FOUND_ANOTHER_SERVICE' &&
        values.companyName !== ''
      ) {
        message = `${message}:${selectedSubReason?.label}:${values.companyName}`;
      } else {
        message = `${message}:${selectedSubReason?.label}`;
      }
    }
    const action = dispatch(cancelMatch([match.id, reason, message]));

    try {
      await action.unwrap();
    } catch (e) {
      setError(getErrorMessage(e));
      actions.setSubmitting(false);
    }
  };

  const closeForm = () => {
    setError(null);
    close();
  };

  const initialValues: CancelValues = {
    reason: '',
    subReason: '',
    companyName: '',
  };
  const selectedOption = reasonOptions.find(
    option => option.value === selectedReason
  );

  return (
    <div className='infoBox'>
      <h3>Cancel Match</h3>
      {error && <p className='warningMessage u-push__bottom--sm'>{error}</p>}
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <p className='u-push__bottom--lg'>
              Are you sure you want to cancel this Match? If a driver has
              already accepted, you may be charged a cancellation fee per your
              contract terms.
            </p>
            <FormGroup
              label='Cancellation Reason'
              labelFor='reason'
              labelInfo='(Required)'
            >
              <Field
                as={HTMLSelect}
                name='reason'
                options={reasonOptions}
                className='select'
                required
                large
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedReason(event.target.value);
                  setFieldValue('reason', event.target.value);
                  const selectedReason = reasonOptions.find(
                    option => option.value === event.target.value
                  );
                  if (selectedReason && selectedReason.subOptions) {
                    const firstSubReason =
                      selectedReason.subOptions[0]?.value || '';
                    setFieldValue('subReason', firstSubReason);
                  } else {
                    setFieldValue('subReason', '');
                  }
                }}
              />
            </FormGroup>

            {selectedOption && selectedOption.subOptions ? (
              <FormGroup
                label='Sub Reason'
                labelFor='subReason'
                labelInfo='(Required)'
              >
                <Field
                  as={HTMLSelect}
                  name='subReason'
                  options={
                    selectedOption?.subOptions?.map(subOption => ({
                      label: subOption.label,
                      value: subOption.value,
                    })) || []
                  }
                  className='select'
                  required
                  large
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setFieldValue('subReason', event.target.value);
                  }}
                />
              </FormGroup>
            ) : null}
            {selectedOption &&
            selectedOption.value === 'FOUND_ANOTHER_SERVICE' ? (
              <FormGroup
                label='Company Name'
                labelFor='companyName'
                labelInfo='(Optional)'
              >
                <Field
                  as={TextArea}
                  name='companyName'
                  className='select'
                  large
                  fill
                  style={{ font: 'inherit' }}
                />
              </FormGroup>
            ) : null}
            <Row end='xs' between='xs'>
              <Col sm xs={12}>
                <Button
                  className='no-wrap buttonSecondary u-push__right--sm'
                  large
                  onClick={closeForm}
                >
                  Back
                </Button>
                <Button
                  type='submit'
                  large
                  loading={isSubmitting}
                  disabled={isSubmitting || !values.reason}
                  rightIcon='chevron-right'
                  className='no-wrap warningButton'
                >
                  Cancel Match
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
