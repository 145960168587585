import { Button, Checkbox, Radio } from '@blueprintjs/core';
import { useFormikContext } from 'formik';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MatchData } from '../../lib/actions/MatchAction';
import DateTimeTZInput from '../form/DateTimeTZInput';
import FormikRadioGroup from '../form/FormikRadioGroup';
import * as yup from 'yup';
import { ObjectSchema, TestContext } from 'yup';
import { isObject } from '../../lib/Utility';
import FieldError from '../form/FieldError';
import { Col, Row } from 'react-flexbox-grid';
import ContractSelect from './ContractSelect';
import { useSelector } from 'react-redux';
import { selectUserContract } from '../../lib/reducers/userSlice';
import { Contract } from '../../lib/actions/UserAction';
import store from '../../lib/store';
import { useFeatureFlag } from '../../lib/Hooks';

export type ScheduleValues = { timezone: string } & PickRequired<
  MatchData,
  'scheduled'
> &
  PickOptional<
    MatchData,
    'dropoff_at' | 'pickup_at' | 'vehicle_class_id' | 'contract_id'
  >;

export function isScheduleValues(values: unknown): values is ScheduleValues {
  return isObject(values, ['scheduled', 'timezone']);
}

function getBlockedDate(
  datetime: Moment | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  values: ScheduleValues
): string | null {
  if (datetime?.month() === 11 && datetime.date() === 25)
    return 'on Christmas Day';
  return null;
}

function getDisabledDates(values: ScheduleValues) {
  const start = moment().tz(values.timezone);
  const end = start.clone().add(60, 'days');
  const days: Moment[] = [];
  let current = start;

  while (end.isSameOrAfter(current)) {
    if (getBlockedDate(current, values)) {
      days.push(current);
    }
    current = current.clone().add(1, 'day');
  }

  return days;
}

function getPickupCutoffTime(
  contract: Contract | null,
  timezone: string,
  targetDate?: Moment
) {
  if (contract?.sla?.type !== 'time_of_day_sla') return;

  const { pickup_cutoff_time } = contract.sla;

  const date = moment(targetDate).format('YYYY-MM-DD');

  return moment(date + 'T' + pickup_cutoff_time).tz(timezone, true);
}

function getEarliestScheduleTime(cutoffTime?: Moment) {
  const earliestScheduleTime = moment().add(90, 'minutes');
  if (cutoffTime && cutoffTime.diff(earliestScheduleTime) < 0)
    return moment().startOf('day').add(1, 'day');

  return earliestScheduleTime;
}

function getDeliveryTime(contract: Contract | null, timezone: string) {
  if (contract?.sla?.type !== 'time_of_day_sla') return;

  const { delivery_end_time } = contract.sla;

  return moment(delivery_end_time, 'HH:mm:ss').tz(timezone, true);
}

function isTodayBlocked(cutoffTime?: Moment): boolean {
  if (!cutoffTime) return false;

  const timezone = cutoffTime.tz();
  const now = timezone ? moment().tz(timezone) : moment();

  return now.diff(cutoffTime) >= 0;
}

function validateOperationalDay(name: string) {
  return (
    value: Moment | string | null | undefined,
    { options, createError, path }: TestContext<unknown>
  ) => {
    const values = options.context;
    if (!isScheduleValues(values)) return true;
    const error = getBlockedDate(value ? moment(value) : null, values);

    if (error) return createError({ message: name + ' ' + error, path });

    return true;
  };
}

function validatePickupIsAfterDropoffDate(
  pickup_at: ScheduleValues['pickup_at'],
  context: TestContext<unknown>
) {
  const values = context.options.context;
  if (!isScheduleValues(values)) return true;

  const { dropoff_at } = values;
  if (pickup_at && dropoff_at && moment(pickup_at).diff(moment(dropoff_at)) > 0)
    return false;

  return true;
}

function pickupIsBeforeCutoffTime(
  pickup_at: ScheduleValues['pickup_at'],
  { options: { context: values }, createError, path }: TestContext<unknown>
) {
  if (!isScheduleValues(values)) return true;

  const { contract_id, timezone } = values;

  const contract = store
    .getState()
    .user.contracts.find(c => c.id === contract_id);

  if (!contract || !pickup_at) return true;

  const pickupAt = moment(pickup_at);
  const cutoffTime = getPickupCutoffTime(contract, timezone, pickupAt);

  if (!cutoffTime) return true;

  if (pickupAt.diff(cutoffTime) <= 0) return true;

  return createError({
    message: 'Pickup time cannot be after ' + cutoffTime.format('h:mma'),
    path,
  });
}

export const matchScheduleSchema: ObjectSchema<
  Omit<ScheduleValues, 'vehicle_class_id' | 'contract_id'>
> = yup.object({
  scheduled: yup
    .bool()
    .required('Date is required')
    .test('nowIsOperationalDay', (scheduled, values) =>
      scheduled === false
        ? validateOperationalDay('Matches cannot be placed')(moment(), values)
        : true
    ),
  pickup_at: yup
    .string()
    .nullable()
    .when('scheduled', {
      is: true,
      then: schema => schema.required('Pickup time is required for scheduling'),
    })
    .test(
      'pickupIsBeforeDropoff',
      'Pickup time must be before dropoff time',
      validatePickupIsAfterDropoffDate
    )
    .test('pickupIsBeforeCutoffTime', pickupIsBeforeCutoffTime)
    .test(
      'pickupIsOperationalDay',
      validateOperationalDay('Pickup time cannot be')
    ),
  dropoff_at: yup
    .string()
    .nullable()
    .test(
      'dropoffIsOperationalDay',
      validateOperationalDay('Dropoff time cannot be')
    ),
  timezone: yup.string().required(),
});

type ScheduledInputProps = {
  name: string;
};

function ScheduledInput({ name }: ScheduledInputProps) {
  const {
    setFieldValue,
    values: { dropoff_at, scheduled, pickup_at, timezone, contract_id },
  } = useFormikContext<ScheduleValues>();

  const contract = useSelector(selectUserContract(contract_id));

  const cutoffTime = useMemo(
    () => getPickupCutoffTime(contract, timezone),
    [contract, timezone]
  );
  const blockToday = isTodayBlocked(cutoffTime);

  useEffect(() => {
    if (blockToday && !scheduled) {
      setFieldValue('scheduled', undefined, true);
    }
  }, [scheduled, blockToday, setFieldValue]);

  useEffect(() => {
    if (!scheduled) {
      if (pickup_at) {
        setFieldValue('pickup_at', undefined, true);
      }

      if (dropoff_at) {
        setFieldValue('dropoff_at', undefined, true);
      }
    }
  }, [scheduled, pickup_at, dropoff_at, setFieldValue]);

  return (
    <FormikRadioGroup
      label='DATE'
      name={name}
      type='boolean'
      className='sectionLabel'
    >
      <Radio
        label={'Now'}
        className='radioLabel scheduledRadio'
        value='false'
        disabled={blockToday}
        labelElement={
          <label htmlFor='Now' className='radioLabelInner'>
            – Ready for pickup now{' '}
            {blockToday ? (
              <span className='u-text--warning'>
                <br />– Not available for today after{' '}
                {cutoffTime?.format('h:mma')}
              </span>
            ) : (
              ''
            )}
          </label>
        }
      />
      <Radio
        label='Later'
        className='radioLabel scheduledRadio'
        value='true'
        labelElement={
          <label htmlFor='Later' className='radioLabelInner'>
            – Schedule for later
          </label>
        }
      />
      <FieldError name={name} ignoreTouched />
    </FormikRadioGroup>
  );
}

function DateInputs() {
  const [dropoffScheduled, setDropoffScheduled] = useState(false);
  const { setFieldValue, setFieldTouched, values } =
    useFormikContext<ScheduleValues>();
  const { dropoff_at, scheduled, timezone, contract_id } = values;

  const contract = useSelector(selectUserContract(contract_id));
  const deliveryTime = useMemo(
    () => getDeliveryTime(contract, timezone),
    [contract, timezone]
  );

  const toggleDropoffScheduled = useCallback(
    (scheduled: boolean) => {
      setDropoffScheduled(scheduled);
      if (!scheduled && dropoff_at) {
        // Formik triggers a remount on any value change, causing an infinite loop. This breaks that loop by allowing the function to rerender and recognize the change to dropoffScheduled
        setTimeout(() => {
          setFieldTouched('dropoff_at', true, false);
          setFieldValue('dropoff_at', null, true);
        }, 0);
      }
    },
    [setFieldValue, setFieldTouched, dropoff_at]
  );

  useEffect(() => {
    if (deliveryTime && (dropoffScheduled || dropoff_at)) {
      toggleDropoffScheduled(false);
    }
  }, [deliveryTime, dropoffScheduled, dropoff_at, toggleDropoffScheduled]);

  useEffect(() => {
    if (scheduled) {
      if (dropoff_at && !dropoffScheduled) {
        setDropoffScheduled(true);
      }
    } else if (dropoffScheduled) {
      setDropoffScheduled(false);
    }
  }, [dropoff_at, dropoffScheduled, scheduled]);

  const disabledDates = useMemo(() => getDisabledDates(values), [values]);

  const showDropoffAt = useFeatureFlag('show_dropoff_at');

  const defaultTime = new Date();

  defaultTime.setHours(12, 12, 0, 0);

  const maxTime = getPickupCutoffTime(contract, timezone);
  const minDate = getEarliestScheduleTime(maxTime);
  const maxDate = moment().add(365, 'days');

  return (
    <Row>
      <Col xs={12} md={6}>
        <h3>
          {contract?.sla?.type === 'time_of_day_sla'
            ? 'Pickup After'
            : 'Pickup At'}
        </h3>
        <DateTimeTZInput
          placeholder='Select Time'
          name='pickup_at'
          timezoneName='timezone'
          disabledDates={disabledDates}
          defaultTime={defaultTime}
          maxTime={maxTime}
          minDate={minDate}
          maxDate={maxDate}
          stepSize={15}
        />
        <FieldError name='pickup_at' />
      </Col>
      {showDropoffAt && (
        <Col xs={12} md={6}>
          <h3>
            Dropoff {dropoffScheduled && 'By'}{' '}
            <Checkbox
              checked={!dropoffScheduled}
              label='ASAP'
              name='asap'
              onChange={event =>
                toggleDropoffScheduled(!event.currentTarget.checked)
              }
              className='inlineCheckbox'
              disabled={!!deliveryTime}
            />
          </h3>
          {dropoffScheduled ? (
            <>
              <DateTimeTZInput
                placeholder='Select Time'
                name='dropoff_at'
                timezoneName='timezone'
                disabledDates={disabledDates}
                defaultTime={defaultTime}
                minDate={minDate}
                maxDate={maxDate}
                stepSize={15}
              />
              <FieldError name='dropoff_at' />
            </>
          ) : (
            <div>
              <p>
                {deliveryTime ? (
                  <span>
                    It will be delivered by {deliveryTime.format('h:mma')}.
                    Setting a dropoff time is not available for this service
                    level.
                  </span>
                ) : (
                  <span>
                    It will be delivered <b>as soon as possible</b>.To pick a
                    specific time, uncheck the ASAP checkbox above.
                  </span>
                )}
              </p>
              <Button
                className='secondaryButton dropoffMobileButton'
                minimal
                icon='time'
                onClick={() => toggleDropoffScheduled(true)}
                fill
                disabled={!!deliveryTime}
              >
                Choose Time
              </Button>
            </div>
          )}
        </Col>
      )}
    </Row>
  );
}

export default function MatchScheduler() {
  const {
    values: { scheduled },
  } = useFormikContext<ScheduleValues>();

  return (
    <div className='MatchScheduler'>
      <div className='MatchScheduler__container'>
        <ContractSelect />
      </div>
      <div className='panelDivider' />
      <div className='MatchScheduler__container'>
        <ScheduledInput name='scheduled' />
      </div>

      {scheduled && <DateInputs />}
    </div>
  );
}
