import { Vehicle } from '../../../lib/actions/MatchAction';

type Props = { vehicle: Vehicle };

export default function VehicleName({ vehicle }: Props) {
  return (
    <div
      style={{
        display: 'inline-block',
        backgroundColor: '#cdced0',
        borderRadius: '20px',
        padding: '0px 10px',
        margin: '0px 0px 0px 0.25rem',
      }}
    >
      {vehicle.vehicle_class.type.name}
    </div>
  );
}
