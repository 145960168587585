import { Button, Callout, InputGroup, Menu, MenuItem } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { Driver, Match, VehicleClass } from '../../../lib/actions/MatchAction';
import { useState } from 'react';
import { handleDriverSelectSubmit } from '../../../lib/Utility';
import { DeliverProBadge } from '../../deliverPro/DeliverProBadge';
import VehicleName from './DriverName';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../lib/reducers/userSlice';

export type SelectByEmailValues = { email?: string };
const selectByEmailSchema: ObjectSchema<SelectByEmailValues> = yup
  .object()
  .shape({
    email: yup.string().optional(),
  });

type PreferredDriverSelectByEmailProps = {
  defaultDriverId?: string | null | undefined;
  currentPreferredDriver?: Driver | undefined;
  minVehicleClass?: VehicleClass;
  onSelect?: (driver: Driver | undefined) => void;
  confirmDefaultDriver?: (defaultDriver: Driver) => void;
  padding?: boolean;
  match?: Match;
};

function setDefaultDriver(driver: Driver, setter?: (driver: Driver) => void) {
  return setter ? (
    <div
      className='defaultDriverButton'
      onClick={() => {
        setter(driver);
      }}
    >
      Make Default
    </div>
  ) : (
    <></>
  );
}

export default function PreferredDriverSelectByEmail({
  minVehicleClass,
  onSelect,
  padding = true,
  match,
  confirmDefaultDriver,
}: PreferredDriverSelectByEmailProps) {
  const [driverOption, setDriverOption] = useState<Driver | undefined>();
  const [showDriverOption, setShowDriverOption] = useState(false);
  const shipper = useSelector(selectUser);

  const handleSubmit = async (
    values: SelectByEmailValues,
    { setFieldError }: FormikHelpers<SelectByEmailValues>
  ) => {
    const { email } = values;
    const submitResult = await handleDriverSelectSubmit(
      'email',
      email,
      setFieldError,
      match
    );
    if (submitResult !== undefined) {
      setDriverOption(submitResult[0]);
      setShowDriverOption(true);
    } else {
      setShowDriverOption(false);
      setDriverOption(undefined);
    }
  };

  const renderOption = () => {
    if (driverOption) {
      const disabled =
        minVehicleClass != null &&
        (driverOption.vehicle.vehicle_class.type.index <
          minVehicleClass.type.index ||
          driverOption.vehicle.vehicle_class.index < minVehicleClass.index);

      return (
        <Menu className='driverSelectContainer'>
          <MenuItem
            style={{ width: '100%' }}
            disabled={disabled}
            text={`${driverOption.first_name} ${driverOption.last_name}`}
            onClick={() => {
              setShowDriverOption(false);
              if (onSelect) onSelect(driverOption);
            }}
            labelElement={
              <div className='driverLabels'>
                <DeliverProBadge driver={driverOption} />
                <VehicleName vehicle={driverOption.vehicle} />
              </div>
            }
          ></MenuItem>

          <div style={{ color: '#616B7A' }}>
            {shipper &&
            shipper.default_driver &&
            shipper.default_driver.id === driverOption.id ? (
              <>
                <FontAwesomeIcon icon={faStar} /> Default
              </>
            ) : (
              setDefaultDriver(driverOption, confirmDefaultDriver)
            )}
          </div>
        </Menu>
      );
    }
  };

  return (
    <Col xs={12} className={padding ? 'u-push__top--lg' : ''}>
      <Row>
        <Col xs={12}>
          <Formik
            validateOnMount
            initialValues={{ email: '' } as SelectByEmailValues}
            validationSchema={selectByEmailSchema}
            onSubmit={handleSubmit}
          >
            {({ submitForm, handleChange, errors }) => (
              <>
                <InputGroup
                  placeholder='user@frayt.com'
                  name='email'
                  fill={true}
                  asyncControl={true}
                  onChange={handleChange}
                  rightElement={
                    <Button
                      className='selectByEmailButton'
                      icon='search'
                      minimal={true}
                      onClick={() => submitForm()}
                    />
                  }
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      submitForm();
                    }
                  }}
                />
                {showDriverOption && (
                  <div className='selectByEmailPopover'>{renderOption()}</div>
                )}
                {errors.email && (
                  <Callout
                    className='preferredDriverSelectCallout'
                    intent={'warning'}
                    style={{ marginTop: '15px' }}
                  >
                    {errors.email}
                  </Callout>
                )}
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Col>
  );
}
