export const DeliverProBadgeSVG = ({ fill }: { fill: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={16}
    viewBox='0 0 640 512'
    fill={fill}
  >
    <path d='M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm40 128h56c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24zm56 80c8.8 0 16-7.2 16-16s-7.2-16-16-16h-32v32h32zm80-56c0-13.3 10.7-24 24-24h56c35.3 0 64 28.7 64 64 0 21.9-11 41.2-27.7 52.7l24.1 38.5c7 11.2 3.6 26-7.6 33.1s-26 3.6-33.1-7.6l-33-52.7H288v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V184zm48 56h32c8.8 0 16-7.2 16-16s-7.2-16-16-16h-32v32zm200-80c48.6 0 88 39.4 88 88v16c0 48.6-39.4 88-88 88s-88-39.4-88-88v-16c0-48.6 39.4-88 88-88zm-40 88v16c0 22.1 17.9 40 40 40s40-17.9 40-40v-16c0-22.1-17.9-40-40-40s-40 17.9-40 40z' />
  </svg>
);
