import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export type Platform = 'marketplace' | 'deliver_pro';

export type AppState = {
  platform: Platform;
};

const initialState: AppState = {
  platform: 'marketplace',
};

export const setPlatform = createAction<Platform>('app/setPlatform');

const reducerName = 'app';

const appSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addCase(setPlatform, (state, action: PayloadAction<Platform>) => {
      state.platform = action.payload;
    }),
});

export default appSlice.reducer;
export const selectPlatform = (state: RootState) => state.app.platform;
