import { Callout, Card, Text } from '@blueprintjs/core';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faCar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-flexbox-grid';
import { Driver } from '../../../lib/actions/MatchAction';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { DeliverProBadge } from '../../deliverPro/DeliverProBadge';
import { faStar, faDoNotEnter } from '@fortawesome/pro-solid-svg-icons';
import { selectUser, updateUser } from '../../../lib/reducers/userSlice';
import { useAppDispatch, useAppSelector } from '../../../lib/store';

type DriverDataCardProps = {
  driver: Driver;
  minimal?: boolean;
  panel?: boolean;
  defaultDriver?: boolean;
};

export function DriverDataCard({
  driver,
  minimal = false,
  panel = false,
}: DriverDataCardProps) {
  const dispatch = useAppDispatch();
  const shipper = useAppSelector(selectUser);

  const formatLastActive = (driver: Driver) =>
    driver.current_location
      ? moment(driver.current_location.created_at).fromNow()
      : 'Unknown';

  const [lastActive, setLastActive] = useState(
    formatLastActive(driver as unknown as Driver)
  );

  useEffect(() => {
    setLastActive(formatLastActive(driver as unknown as Driver));
  }, [driver]);

  const confirmationMessage = (driver: Driver) => {
    return (
      `Are you sure you want to remove this driver as the default? ` +
      `${driver.first_name} ${driver.last_name} will no longer be chosen ` +
      `as the default Preferred Driver.`
    );
  };

  const handleRemoveDefaultDriver = () => {
    if (window.confirm(confirmationMessage(driver))) {
      dispatch(updateUser({ default_driver_id: '' }));
    }
  };

  if (minimal)
    return (
      <Row>
        <Col xs={12} className='u-push__top--lg'>
          <Callout
            className='preferredDriverSelectCallout'
            intent='success'
            icon={null}
          >
            <div
              className='preferredDriverSelectCallout--container'
              style={
                panel
                  ? { flexDirection: 'column', alignItems: 'flex-start' }
                  : {}
              }
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon
                  icon={faCar}
                  className='preferredDriverSelectCallout--icon'
                />{' '}
                <Text className='preferredDriverSelectCallout--title'>
                  Driver:
                </Text>
              </div>
              <Text className='preferredDriverSelectCallout--content'>
                {driver.first_name} {driver.last_name} ({driver.email}) is the
                Preferred Driver for your order.
              </Text>
            </div>
          </Callout>
        </Col>
      </Row>
    );

  return (
    <Row>
      <Col xs={12} sm={12} md={9} lg={7} className='u-push__top--lg'>
        <Card className='driverDataCard'>
          <Row>
            <Col xs className='driverDataCard__personal'>
              <FontAwesomeIcon
                icon={faUser}
                className='driverDataCard__profile'
              />
              <Text className='driverDataCard__nameHeading'>
                {driver.first_name} {driver.last_name}
              </Text>{' '}
              <DeliverProBadge driver={driver} />
              <Text className='driverDataCard__subheader'>
                {driver.phone_number}
              </Text>
            </Col>
            <Col sm>
              <Row end='lg'>
                {shipper &&
                shipper.default_driver &&
                shipper.default_driver.id === driver.id ? (
                  <div>
                    <Text
                      className='label__warning'
                      onClick={handleRemoveDefaultDriver}
                    >
                      <FontAwesomeIcon icon={faDoNotEnter} />
                      <span>&nbsp;REMOVE</span>
                    </Text>
                    <Text className='label'>
                      <FontAwesomeIcon icon={faStar} />
                      <span>&nbsp;DEFAULT</span>
                    </Text>
                  </div>
                ) : (
                  <Text className='label'>DRIVER</Text>
                )}
              </Row>
            </Col>
          </Row>
          <Row
            className='u-push__top--lg'
            style={{ border: '1px solid #cdced0' }}
          ></Row>
          <Row className='u-push__top--lg' between='xs'>
            <Col xs={4}>
              <Text className='driverDataCard__cellHeader'>Vehicle</Text>
              <Text className='driverDataCard__subheader'>
                {driver.vehicle.vehicle_model}
              </Text>
            </Col>
            <Col xs={4}>
              <Text className='driverDataCard__cellHeader'>Deliveries</Text>
              <Text className='driverDataCard__subheader'>
                {driver.completed_matches}
              </Text>
            </Col>
            <Col xs={4}>
              <Text className='driverDataCard__cellHeader'>Last Active</Text>
              <Text className='driverDataCard__subheader'>{lastActive}</Text>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
