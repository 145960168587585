import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { Grid, Row, Col } from 'react-flexbox-grid';
import csv from 'csvtojson';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Button, Spinner } from '@blueprintjs/core';
import { EstimateStopValues, EstimateValues } from './steps/Estimate';
import {
  getPreferredDriver,
  selectEstimateStatus,
  selectVehicleTypes,
} from '../../lib/reducers/estimateSlice';
import { useSelector } from 'react-redux';
import {
  MatchStopItemType,
  SignatureType,
} from '../../lib/actions/MatchAction';
import { useField } from 'formik';
import { useAppDispatch, useAppSelector } from '../../lib/store';
import { selectUserContracts } from '../../lib/reducers/userSlice';
import { Contract } from '../../lib/actions/UserAction';
import { selectPlatform } from '../../lib/reducers/appSlice';
import { v4 } from 'uuid';
import { toBoolean, toNumber } from '../../lib/Convert';

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#999',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#999',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: 100,
  verticalAlign: 'middle',
};

const focusedStyle: CSSProperties = {
  borderColor: '#2196f3',
};

const acceptStyle: CSSProperties = {
  borderColor: '#00e676',
};

const rejectStyle: CSSProperties = {
  borderColor: '#ff1744',
};

export type MatchCsvUploaderProps = {
  onLoad: (payload: Partial<EstimateValues>) => unknown;
};

type MatchCsvRow = {
  origin_address?: string;
  destination_address?: string;
  vehicle_type?: number | string;
  vehicle_class?: number | string;
  contract_key?: string;
  pickup_at?: string;
  dropoff_at?: string;
  bill_of_lading_required?: boolean | string;
  origin_photo_required?: boolean | string;
  destination_photo_required?: boolean | string;
  sender_name?: string;
  sender_email?: string;
  sender_phone?: string;
  notify_sender?: boolean | string;
  pickup_notes?: string;
  po?: string;
  route_identifier?: string;
  item_description?: string;
  item_pieces?: number | string;
  item_total_weight?: number | string;
  item_width?: number | string;
  item_length?: number | string;
  item_height?: number | string;
  item_volume?: number | string;
  barcode?: string;
  barcode_pickup_required?: boolean | string;
  barcode_delivery_required?: boolean | string;
  declared_value?: number | string;
  load_unload?: boolean | string;
  dropoff_notes?: string;
  signature_required?: boolean | string;
  signature_type?: string;
  signature_instructions?: string;
  recipient_name?: string;
  recipient_email?: string;
  recipient_phone?: string;
  notify_recipient?: boolean | string;
  preferred_driver_email?: string;
};

const uniqueFields: (keyof MatchCsvRow)[] = [
  'origin_address',
  'contract_key',
  'vehicle_class',
  'vehicle_type',
  'pickup_at',
  'dropoff_at',
  'bill_of_lading_required',
  'origin_photo_required',
  'sender_name',
  'sender_email',
  'sender_phone',
  'notify_sender',
  'pickup_notes',
  'route_identifier',
  'preferred_driver_email',
];

const validateRow = (
  first: MatchCsvRow,
  other: MatchCsvRow,
  line: number
): unknown[] => {
  return uniqueFields
    .filter(field => first[field] !== other[field])
    .map(field => (
      <>
        <b>{field}</b> value at the line #{line} should be equal to{' '}
        <b>{field}</b> value at the line #1
      </>
    ));
};

const defaultError = 'Something went wrong. Please try again later.';
const errorsMap: { [key: string]: string } = {
  'file-too-large': 'The file should not be larger than 1MB.',
  'file-invalid-type': 'The file you tried to upload is not a CSV.',
  'too-many-files': 'Only one file is allowed at a time.',
  'file-too-small': 'The file you tried to upload is empty.',
};

const getContractId = (
  contracts: Contract[],
  key: string | null
): string | null => {
  const contract = contracts.find(c => c.contract_key === key);

  return contract?.id || null;
};

export function MatchCsvUploader(props: MatchCsvUploaderProps) {
  const [dropZoneIsOpen, setDropZoneIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [csvErrors, setCsvErrors] = useState<unknown[]>();
  const estimating = useSelector(selectEstimateStatus) === 'loading';
  const contracts = useSelector(selectUserContracts);
  const platform = useSelector(selectPlatform);
  const dispatch = useAppDispatch();
  const [, , { setValue, setTouched }] = useField('preferred_driver_id');
  const vehicleTypes = useAppSelector(selectVehicleTypes);

  /**
   * Gets the ID of the driver using the supplied email address. Must do this through Redux
   * so that the errors in the StepFooter can be updated properly
   *
   * @param email Preferred Driver's email
   * @returns the ID of the driver
   */
  const getDriverID = useCallback(
    async (email: string | undefined): Promise<string | undefined> => {
      if (!email) return;

      const response = await dispatch(
        getPreferredDriver({ type: 'email', query: email })
      ).unwrap();
      setIsLoading(false);
      return response.data[0]?.id;
    },
    [dispatch]
  );

  const onLoadCsv = useCallback(
    async (_evt: ProgressEvent, reader: FileReader) => {
      const result = await csv({ trim: true }).fromString(
        reader.result?.toString() || ''
      );
      const rows = result as MatchCsvRow[];
      const [first, ...remainingRows] = rows;

      if (!first) {
        setCsvErrors(['The file you uploaded is empty.']);
        return;
      }

      const validation = remainingRows.flatMap((row, index: number) =>
        validateRow(first, row, index + 2)
      );

      if (!validation.length) {
        const contract_id = getContractId(
          contracts,
          first.contract_key || null
        );

        const vehicle_type_key = first.vehicle_type;
        const vehicle_class_index = toNumber(first.vehicle_class);
        const vehicle_type = vehicleTypes.find(
          type => type.key === vehicle_type_key
        );
        const vehicle_class =
          vehicle_type?.classes.find(c => c.index === vehicle_class_index) ||
          vehicle_type?.classes[0];

        const self_sender = !(
          first.sender_name ||
          first.sender_phone ||
          first.sender_email ||
          first.notify_sender
        );
        const preferred_driver_id = await getDriverID(
          first.preferred_driver_email
        );

        const init: EstimateValues = {
          stops: [],
          origin_address: first.origin_address,
          vehicle_class_id: vehicle_class?.id,
          pickup_at: moment(first.pickup_at).toISOString(),
          dropoff_at: moment(first.dropoff_at).toISOString(),
          bill_of_lading_required: toBoolean(first.bill_of_lading_required),
          origin_photo_required: toBoolean(first.origin_photo_required),
          pickup_notes: first.pickup_notes,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          scheduled: !!(first.pickup_at || first.dropoff_at),
          self_sender,
          contract_id,
          route_identifier: first.route_identifier,
          platform: first.preferred_driver_email
            ? 'preferred_driver'
            : 'marketplace',
          preferred_driver_id: preferred_driver_id,
        };

        setValue(preferred_driver_id);
        setTouched(true, false);

        const estimate: EstimateValues = {
          ...init,
          sender: toBoolean(self_sender, true)
            ? null
            : {
                name: first.sender_name || '',
                email: first.sender_email,
                phone_number: first.sender_phone,
                notify: toBoolean(first.notify_sender),
              },
          po: rows.length === 1 ? first.po : null,
        };

        rows.forEach((row, index) => {
          const self_recipient = !(
            row.recipient_name ||
            row.recipient_phone ||
            row.recipient_email ||
            row.notify_recipient
          );

          const dimsAreSet = !!(
            row.item_width ||
            row.item_length ||
            row.item_height
          );

          const initStop: EstimateStopValues = {
            id: `${index}`,
            destination_address: row.destination_address,
            drag_id: v4(),
            po: row.po,
            index: index,
            self_recipient,
            has_load_fee: toBoolean(row.load_unload),
            items: [
              {
                id: `${index}`,
                description: row.item_description,
                pieces: toNumber(row.item_pieces),
                volume: dimsAreSet ? undefined : toNumber(row.item_volume),
                width: dimsAreSet ? toNumber(row.item_width) : undefined,
                length: dimsAreSet ? toNumber(row.item_length) : undefined,
                height: dimsAreSet ? toNumber(row.item_height) : undefined,
                declared_value: toNumber(row.declared_value) || null,
                barcode: row.barcode,
                barcode_pickup_required: toBoolean(row.barcode_pickup_required),
                barcode_delivery_required: toBoolean(
                  row.barcode_delivery_required
                ),
                type: MatchStopItemType.Item,
                weight:
                  (toNumber(row.item_total_weight) || 0) /
                  (toNumber(row.item_pieces) || 1),
              },
            ],
            needs_pallet_jack: false,
          };

          const signature_type = `${
            row.signature_type || SignatureType.Electronic
          }`.toLowerCase() as SignatureType;

          const stop: EstimateStopValues = {
            ...initStop,
            recipient: toBoolean(self_recipient, true)
              ? null
              : {
                  name: row.recipient_name || '',
                  email: row.recipient_email,
                  phone_number: row.recipient_phone,
                  notify: toBoolean(row.notify_recipient),
                },
            signature_type,
            signature_required: toBoolean(row.signature_required),
            signature_instructions: row.signature_instructions,
            has_load_fee: toBoolean(row.load_unload),
            delivery_notes: row.dropoff_notes,
            destination_photo_required: toBoolean(
              row.destination_photo_required
            ),
          };

          estimate.stops.push(stop);
        });

        props.onLoad(estimate);
      } else {
        setCsvErrors(validation);
      }
      setIsLoading(false);
    },
    [props, contracts, getDriverID, setValue, setTouched, vehicleTypes]
  );

  const onDrop = useCallback(
    (files: File[]) => {
      const file = files[0];
      if (!file) return;
      if (file.size === 0) {
        setCsvErrors(["The file you're uploading is empty."]);
        return;
      }
      setIsLoading(true);
      const reader = new FileReader();
      reader.onabort = () => setIsLoading(false);
      reader.onerror = () => setIsLoading(false);
      reader.onload = evt => onLoadCsv(evt, reader).then();
      reader.readAsText(file);
    },
    [onLoadCsv]
  );

  const onDropRejected = useCallback((rejections: FileRejection[]) => {
    const rejectionError = rejections[0]?.errors[0];

    const errors =
      (rejectionError?.code && errorsMap[rejectionError.code]) || defaultError;

    setCsvErrors([errors]);
  }, []);

  const onFileDialogOpen = useCallback(() => setCsvErrors([]), []);
  const onDragOver = useCallback(() => setCsvErrors([]), []);
  const handleClick = useCallback(() => {
    if (dropZoneIsOpen) setCsvErrors([]);
    setDropZoneIsOpen(!dropZoneIsOpen);
  }, [dropZoneIsOpen]);

  const dz = useDropzone({
    onDrop,
    onDragOver,
    onFileDialogOpen,
    onDropRejected,
    accept: { 'text/csv': ['.csv'] },
    maxSize: 1000_000,
    maxFiles: 1,
    multiple: false,
  });
  const { isFocused, isDragAccept, isDragReject } = dz;

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const btnSuffix = dropZoneIsOpen && !isLoading && !estimating ? 'Filled' : '';

  return (
    <div style={{ marginBottom: 5 }}>
      <Grid>
        <Row>
          <Col style={{ paddingLeft: 0 }}>
            <Button
              className={`primaryButton${btnSuffix}${
                platform === 'deliver_pro' ? 'DeliverPro' : ''
              }`}
              active={dropZoneIsOpen && !isLoading}
              onClick={handleClick}
              style={{ marginBottom: 5 }}
              icon={'upload'}
              disabled={isLoading || estimating}
            >
              Upload CSV
            </Button>
          </Col>
          <Col style={{ paddingLeft: '10px', paddingTop: '5px' }}>
            <a
              target='_blank'
              href='https://coda.io/d/Shipper-CSV-Upload_d41RLw_q-F4/Shipper-CSV-Upload-Formatting-Guide_su95w#_luSMI'
              rel='noreferrer'
            >
              CSV File Guide
            </a>
          </Col>
          <Col style={{ paddingLeft: 5 }}>
            {!!csvErrors?.length &&
              csvErrors.map((err: unknown, index) => (
                <p key={`csv-error-${index}`} className={'error'}>
                  {err as string}
                </p>
              ))}
          </Col>
        </Row>
      </Grid>
      {dropZoneIsOpen && (isLoading || estimating) && <Spinner size={40} />}
      {dropZoneIsOpen && !isLoading && !estimating && (
        <div>
          <div {...dz.getRootProps({ style })}>
            <input {...dz.getInputProps()} />
            <p style={{ marginTop: 20 }}>
              Drag & drop some files here, or click to select files
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
