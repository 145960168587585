export const toBoolean = (value: unknown, fallback = false): boolean => {
  if (value === undefined) return fallback;

  const v = `${value || ''}`.trim();

  return /^(true|1|yes|y|t)$/i.test(v);
};

export const toNumber = (
  value: unknown,
  fallback?: number
): number | undefined => {
  if (typeof value === 'number') return value;

  if (typeof value === 'string') {
    const n = +value;
    if (!isNaN(n)) return n;
  }

  if (value === undefined) return fallback;

  return;
};
