import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Navbar, Button, Icon } from '@blueprintjs/core';
import { Grid } from 'react-flexbox-grid';
import { useSelector } from 'react-redux';
import { logoutUser, selectAuthStatus } from '../lib/reducers/userSlice';
import { useAppDispatch } from '../lib/store';
import { DeliverProSVG } from '../assets/svg/deliver-pro-svg';
import { selectPlatform, setPlatform } from '../lib/reducers/appSlice';

export default function AppNavigation() {
  const dispatch = useAppDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const platform = useSelector(selectPlatform);
  const isDeliverPro = platform === 'deliver_pro';
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const authenticated = authStatus === 'succeeded';
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('/deliverpro')) dispatch(setPlatform('deliver_pro'));
  }, [dispatch, pathname]);

  const toggleMenu = () => setShowMobileMenu(!showMobileMenu);
  const logout = () => dispatch(logoutUser());

  let rightNav = <div />;
  let mobileMenu = <div />;

  const links = authenticated ? (
    <>
      <NavLink to='/ship'>
        <Button
          className='bp4-minimal navButton'
          icon='offline'
          text='Ship'
          onClick={toggleMenu}
        />
      </NavLink>
      <NavLink to='/matches'>
        <Button
          className='bp4-minimal navButton'
          icon='properties'
          text='Matches'
          onClick={toggleMenu}
        />
      </NavLink>
      <NavLink to='/settings'>
        <Button
          className='bp4-minimal navButton'
          icon='cog'
          text='Settings'
          onClick={toggleMenu}
        />
      </NavLink>
    </>
  ) : null;

  const leftNav = !isDeliverPro ? (
    <div>
      <div
        onClick={toggleMenu}
        className={
          showMobileMenu
            ? 'mobile-menu-toggle mobile-menu-toggle-hide'
            : 'mobile-menu-toggle'
        }
      >
        <Button className='bp4-minimal navButton' icon='menu' />
      </div>
      <div className='desktop-menu'>
        {links}
        <NavLink to='/support'>
          <Button
            className='bp4-minimal navButton'
            icon='pulse'
            text='Support'
          />
        </NavLink>
      </div>
    </div>
  ) : null;

  mobileMenu = (
    <div
      className={
        showMobileMenu ? 'mobile-menu mobile-menu-show' : 'mobile-menu'
      }
    >
      <div className='mobile-menu-fade'>
        <div className='mobile-menu-link-wrapper'>
          <Icon
            className='bp4-minimal navButton mobile-menu-close'
            icon='cross'
            iconSize={20}
            onClick={toggleMenu}
          />
          {links}
          <NavLink to='/support'>
            <Button
              className='bp4-minimal navButton'
              icon='pulse'
              text='Support'
              onClick={toggleMenu}
            />
          </NavLink>
          {authenticated ? (
            <Button
              className='bp4-minimal navButton'
              icon='lock'
              text='Logout'
              onClick={logout}
            />
          ) : !isDeliverPro ? (
            <NavLink to='/signup'>
              <Button
                className='bp4-minimal navButton'
                icon='cog'
                text='Sign Up'
              />
            </NavLink>
          ) : null}
        </div>
      </div>
    </div>
  );

  if (authenticated) {
    rightNav = (
      <div onClick={logout}>
        <Button
          className='bp4-minimal navButton desktop-logout'
          icon='lock'
          text='Logout'
        />
      </div>
    );
  } else {
    rightNav = !isDeliverPro ? (
      <NavLink to='/signup' reloadDocument>
        <Button
          className='bp4-minimal navButton desktop-logout'
          icon='cog'
          text='Sign Up'
        />
      </NavLink>
    ) : (
      <></>
    );
  }

  if (pathname !== '/choose-platform') {
    return (
      <Navbar className={isDeliverPro ? 'deliver-pro' : ''}>
        {authStatus !== 'pending' && mobileMenu}
        <Grid>
          <Navbar.Group>
            <Link to={isDeliverPro ? '/deliverpro' : '/'}>
              <Navbar.Heading>
                {!isDeliverPro ? (
                  <img
                    src='/img/logo-white.png'
                    alt={'Frayt'}
                    style={{ height: 28, paddingTop: 4 }}
                  />
                ) : (
                  <DeliverProSVG fill={'white'} />
                )}
              </Navbar.Heading>
            </Link>
          </Navbar.Group>
          <Navbar.Group align='right'>
            {authStatus !== 'pending' && (
              <>
                {leftNav}
                {rightNav}
              </>
            )}
          </Navbar.Group>
        </Grid>
      </Navbar>
    );
  } else {
    return <></>;
  }
}
