import { Card, Elevation, FormGroup, Radio, Text } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { DriverDataCard } from './DriverDataCard';
import PreferredDriverSelectByEmail from './PreferredDriverSelectByEmail';
import PreferredDriverSelectByName from './PreferredDriverSelectByName';
import { Driver } from '../../../lib/actions/MatchAction';
import { Shipper } from '../../../lib/actions/ShipperAction';

import { useField } from 'formik';
import {
  selectEstimateMatch,
  selectPreferredDriverType,
  setPreferredDriverType,
} from '../../../lib/reducers/estimateSlice';
import { useAppDispatch, useAppSelector } from '../../../lib/store';
import { selectUser, updateUser } from '../../../lib/reducers/userSlice';

type PreferredDriverSelectOptionProps = {
  label: string;
  caption: string;
  selected: boolean;
  onSelected: () => void;
};

export enum PreferredDriverOption {
  None,
  Recent,
  Email,
}

function PreferredDriverSelectOption({
  label,
  caption,
  selected,
  onSelected,
}: PreferredDriverSelectOptionProps) {
  return (
    <Col xs={12} md={6} lg={4} className='u-push__bottom--lg'>
      <Card
        interactive
        elevation={selected ? Elevation.THREE : Elevation.ONE}
        onClick={() => onSelected()}
        className={
          selected
            ? 'card left-aligned cardSelected preferredDriverSelectPadding'
            : 'card left-aligned preferredDriverSelectPadding'
        }
      >
        <Row>
          <Col sm={12}>
            <Radio
              label={label}
              type='checkbox'
              checked={selected}
              onChange={() => {}}
              className='cardTitle'
            />
          </Col>
          <Col sm={12}>
            <Text>{caption}</Text>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

type PreferredDriverSelectProps = {
  csvPreferredDriverId: string | null | undefined;
};

function confirmation_message(driver: Driver) {
  return (
    `Are you sure you want to set this driver as the default? ` +
    `All future orders will have ${driver.first_name} ${driver.last_name} ` +
    `set as the default Preferred Driver.`
  );
}

function dateDiff(date: string) {
  const expirationDate = new Date(date);
  const today = new Date();
  const difference = expirationDate.getTime() - today.getTime();
  return Math.ceil(difference / (1000 * 3600 * 24));
}

function preferredDriverFeeWaiverMessage(shipper: Shipper) {
  const preferredDriverFeeWaiver = shipper.location?.waived_fees?.find(
    fee => fee.fee_type === 'preferred_driver_fee'
  );

  const daysUntilExpiration = preferredDriverFeeWaiver?.expires_at
    ? dateDiff(preferredDriverFeeWaiver.expires_at)
    : 0;

  const maybePluralDays = daysUntilExpiration === 1 ? 'day' : 'days';

  if (preferredDriverFeeWaiver && daysUntilExpiration > 0) {
    return (
      <div className='fee-waiver-label'>
        <span style={{ fontWeight: 'bold' }}>Free</span> for{' '}
        {daysUntilExpiration} more {maybePluralDays}
      </div>
    );
  }
}

export function PreferredDriverSelect({
  csvPreferredDriverId,
}: PreferredDriverSelectProps) {
  const shipper = useAppSelector(selectUser);
  const match = useAppSelector(selectEstimateMatch);
  const preferredDriver = match?.preferred_driver;
  const defaultDriver = shipper?.default_driver;
  const dispatch = useAppDispatch();
  const preferredDriverType = useAppSelector(selectPreferredDriverType);
  const storedDriverType = parseInt(sessionStorage.getItem('driverType') || '');
  const [, { touched }, { setValue, setTouched }] = useField(
    'preferred_driver_id'
  );
  const appropriateDriver =
    preferredDriver || (defaultDriver ? defaultDriver : undefined) || undefined;

  const [currentDefaultDriver, setCurrentDefaultDriver] = useState(
    shipper?.default_driver
  );

  const handleChange = useCallback(
    (
      preferred_driver_id: string | undefined,
      preferredDriverType: PreferredDriverOption
    ) => {
      dispatch(setPreferredDriverType(preferredDriverType));
      setTouched(true, false);
      setValue(preferred_driver_id);
      sessionStorage.setItem('driverType', preferredDriverType.toString());
    },
    [dispatch, setTouched, setValue]
  );

  const confirmDefaultDriver = useCallback(
    (defaultDriver: Driver, preferredDriverType: PreferredDriverOption) => {
      if (shipper && window.confirm(confirmation_message(defaultDriver))) {
        dispatch(updateUser({ default_driver_id: defaultDriver.id }));

        setCurrentDefaultDriver(defaultDriver);

        handleChange(defaultDriver.id, preferredDriverType);
      }
    },
    [shipper, dispatch, setCurrentDefaultDriver, handleChange]
  );

  useEffect(() => {
    if (csvPreferredDriverId && csvPreferredDriverId !== preferredDriver?.id) {
      handleChange(csvPreferredDriverId, PreferredDriverOption.Email);
    }
  }, [csvPreferredDriverId, dispatch, handleChange, preferredDriver?.id]);

  useEffect(() => {
    if (!!appropriateDriver && !touched) {
      handleChange(appropriateDriver.id, PreferredDriverOption.Recent);
    }
  }, [appropriateDriver, touched, handleChange]);

  useEffect(() => {
    if (!match && appropriateDriver && !touched) {
      dispatch(setPreferredDriverType(PreferredDriverOption.Recent));
    }

    // Handle edge case where estimate already has a preferred driver when first visiting the screen
    // Our Redux doesn't persist offline so we have no way to tell which option they used, so default to Email
    if (
      !appropriateDriver &&
      !!preferredDriver &&
      preferredDriverType === PreferredDriverOption.None &&
      !touched
    ) {
      dispatch(setPreferredDriverType(PreferredDriverOption.Email));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='panelDivider' />
      <div style={{ position: 'relative' }}>
        {shipper && preferredDriverFeeWaiverMessage(shipper)}
        <FormGroup
          style={{ display: 'grid' }}
          label='PREFERRED DRIVER'
          inline={true}
          labelFor='driver'
          labelInfo='(Optional)'
          className='u-push__top--lg sectionLabel'
        >
          <Row>
            <PreferredDriverSelectOption
              label='None'
              caption='Automatically assign a driver'
              selected={
                storedDriverType
                  ? storedDriverType === PreferredDriverOption.None
                  : preferredDriverType === PreferredDriverOption.None
              }
              onSelected={() => {
                handleChange('', PreferredDriverOption.None);
              }}
            />
            <PreferredDriverSelectOption
              label='Recent Drivers'
              caption="Choose from a driver you've used before"
              selected={
                storedDriverType
                  ? storedDriverType === PreferredDriverOption.Recent
                  : preferredDriverType === PreferredDriverOption.Recent
              }
              onSelected={() => {
                handleChange(
                  appropriateDriver ? appropriateDriver.id : '',
                  PreferredDriverOption.Recent
                );
              }}
            />
            <PreferredDriverSelectOption
              label='Email'
              caption='Choose any driver by their email address'
              selected={
                storedDriverType
                  ? storedDriverType === PreferredDriverOption.Email
                  : preferredDriverType === PreferredDriverOption.Email
              }
              onSelected={() => {
                handleChange(
                  appropriateDriver ? appropriateDriver.id : '',
                  PreferredDriverOption.Email
                );
              }}
            />
          </Row>
          <Row>
            {(storedDriverType
              ? storedDriverType === PreferredDriverOption.Recent
              : preferredDriverType === PreferredDriverOption.Recent) && (
              <PreferredDriverSelectByName
                defaultDriverId={
                  currentDefaultDriver ? currentDefaultDriver.id : undefined
                }
                minVehicleClass={match?.vehicle_class}
                currentPreferredDriver={appropriateDriver}
                onSelect={selectedDriver => {
                  handleChange(
                    selectedDriver?.id,
                    PreferredDriverOption.Recent
                  );
                }}
                confirmDefaultDriver={defaultDriver => {
                  confirmDefaultDriver(
                    defaultDriver,
                    PreferredDriverOption.Recent
                  );
                }}
              />
            )}
            {(storedDriverType
              ? storedDriverType === PreferredDriverOption.Email
              : preferredDriverType === PreferredDriverOption.Email) && (
              <PreferredDriverSelectByEmail
                defaultDriverId={
                  currentDefaultDriver ? currentDefaultDriver.id : undefined
                }
                minVehicleClass={match?.vehicle_class}
                currentPreferredDriver={appropriateDriver}
                onSelect={selectedDriver => {
                  handleChange(selectedDriver?.id, PreferredDriverOption.Email);
                }}
                confirmDefaultDriver={defaultDriver => {
                  confirmDefaultDriver(
                    defaultDriver,
                    PreferredDriverOption.Email
                  );
                }}
              />
            )}
          </Row>
          {appropriateDriver &&
            (preferredDriverType !== PreferredDriverOption.None ||
              storedDriverType !== PreferredDriverOption.None) && (
              <DriverDataCard
                driver={appropriateDriver}
                defaultDriver={
                  (currentDefaultDriver
                    ? currentDefaultDriver.id
                    : undefined) === appropriateDriver.id
                }
                minimal={
                  preferredDriverType === PreferredDriverOption.Email ||
                  storedDriverType === PreferredDriverOption.Email
                }
              />
            )}
        </FormGroup>
      </div>
    </>
  );
}
