import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import ErrorCallout from '../../ErrorCallout';
import { useSelector } from 'react-redux';
import {
  selectAuthStatus,
  selectUser,
  selectUserErrors,
} from '../../../lib/reducers/userSlice';
import { UserType } from './Login';
import RegistrationForm from './RegistrationForm';
import SignInForm from './SignInForm';
import { ShipperPlatforms } from '../../../lib/actions/ShipperAction';
import { selectPlatform } from '../../../lib/reducers/appSlice';

export type LoginFormType = 'signup' | 'login';

type LoginFormProps = {
  userType: UserType;
  shipScreen: boolean;
  formType?: LoginFormType;
};

type LoginActionsProps = {
  setFormType: Dispatch<SetStateAction<LoginFormType>>;
  isDeliverPro: boolean;
} & LoginFormProps;

function LoginActions({
  userType,
  formType,
  shipScreen,
  isDeliverPro,
  setFormType,
}: LoginActionsProps) {
  const navigate = useNavigate();
  const toggleFormType = () => {
    const newFormType = formType === 'login' ? 'signup' : 'login';
    setFormType(newFormType);
    navigate(`/${newFormType}`);
  };

  return (
    <div>
      {formType === 'signup' && userType === 'shipper' && (
        <RegistrationForm shipScreen={shipScreen} />
      )}
      {formType === 'login' && (
        <SignInForm shipScreen={shipScreen} isDeliverPro={isDeliverPro} />
      )}

      {!isDeliverPro && (
        <div
          className={
            shipScreen ? 'registerButton--shipScreen' : 'registerButton'
          }
        >
          <p
            className={
              shipScreen ? 'registerLabel--shipScreen' : 'registerLabel'
            }
          >
            {formType === 'login'
              ? "Don't have an account?"
              : 'Already have a shipper account?'}
          </p>
          <Button
            text={formType === 'login' ? 'Register' : 'Login'}
            large
            fill
            id='show-login'
            className={shipScreen ? 'secondaryButton' : 'whiteButton'}
            onClick={toggleFormType}
          />
        </div>
      )}
    </div>
  );
}

export default function LoginForm({ userType, shipScreen }: LoginFormProps) {
  const error = useSelector(selectUserErrors);
  const authorized = useSelector(selectAuthStatus) === 'succeeded';
  const user = useSelector(selectUser);
  const isDeliverPro = useSelector(selectPlatform) === 'deliver_pro';

  const navigate = useNavigate();

  const [formType, setFormType] = useState<LoginFormType>(
    isDeliverPro ? 'login' : 'signup'
  );

  useEffect(() => {
    if (authorized && formType === 'signup') {
      navigate('/welcome');
    } else if (authorized && user) {
      const is_multi_platform = (
        ['marketplace', 'deliver_pro'] as ShipperPlatforms
      ).every(p => user.platforms.includes(p));
      const is_dev_pro_user = user.platforms.includes('deliver_pro');

      if (is_multi_platform) navigate('/choose-platform');
      else if (is_dev_pro_user) navigate('/deliverpro/ship');
      else navigate('/ship');
    }
  }, [authorized, navigate, user, formType]);

  return (
    <>
      <ErrorCallout message={error} level='danger' />
      <LoginActions
        userType={userType}
        formType={formType}
        setFormType={setFormType}
        shipScreen={shipScreen}
        isDeliverPro={isDeliverPro}
      />
    </>
  );
}
