import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Agreement,
  NewUserData,
  getUserAgreements,
} from '../../../lib/actions/UserAction';
import AgreementInput, {
  agreementsSchema,
  initialAgreementsValues,
} from './AgreementInput';
import * as yup from 'yup';
import { passwordType } from '../../form/PasswordInput';
import {
  registerUser,
  selectAuthStatus,
  selectUserErrors,
} from '../../../lib/reducers/userSlice';
import { useAppDispatch } from '../../../lib/store';
import { Button, FormGroup, InputGroup, HTMLSelect } from '@blueprintjs/core';
import FieldError from '../../form/FieldError';
import { useDidMount } from '../../../lib/Hooks';
import ErrorCallout from '../../ErrorCallout';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReferrerType } from '../../../lib/actions/MatchAction';

type DPSignupValues = Pick<
  NewUserData,
  | 'referring_driver_id'
  | 'first_name'
  | 'last_name'
  | 'phone'
  | 'company'
  | 'agreements'
  | 'email'
  | 'password'
  | 'commercial'
  | 'platforms'
>;

const signupSchema: yup.ObjectSchema<DPSignupValues> = agreementsSchema.shape({
  commercial: yup
    .boolean()
    .required()
    .isTrue('Only commercial shippers are allowed'),
  referring_driver_id: yup.string().required(),
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  phone: yup.string().required('Phone number is required'),
  company: yup.string().required('Company name is required'),
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: passwordType,
  platforms: yup.array().required(),
});

const referrerTypes: { label: string; value: ReferrerType }[] = [
  { value: ReferrerType.LinkedIn, label: 'LinkedIn' },
  { value: ReferrerType.Google, label: 'Google' },
  { value: ReferrerType.Advertising, label: 'Advertising' },
  { value: ReferrerType.PR, label: 'PR' },
  { value: ReferrerType.Search_Engine, label: 'Search Engine' },
  { value: ReferrerType.Email, label: 'Email' },
  { value: ReferrerType.Friend_Colleague, label: 'Friend Or Colleague' },
  { value: ReferrerType.Driver, label: 'Driver' },
];

export default function DeliverProSignUpForm() {
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const dispatch = useAppDispatch();
  const formik = React.createRef<FormikProps<DPSignupValues>>();
  const didMount = useDidMount();
  const error = useSelector(selectUserErrors);
  const authorized = useSelector(selectAuthStatus) === 'succeeded';
  const navigate = useNavigate();

  const handleFormSubmit = (
    values: DPSignupValues,
    actions: FormikHelpers<DPSignupValues>
  ) => {
    dispatch(registerUser(values)).finally(() => actions.setSubmitting(false));
  };

  const fetchAgreements = useCallback(async () => {
    try {
      const result = await getUserAgreements();
      const { agreement_documents: agreements } = result.data;

      if (formik.current) {
        for (let index = 0; index < agreements.length; index++) {
          const agreement = agreements[index] as Agreement,
            name = `agreements[${index}]`;

          formik.current.setFieldValue(name + 'document_id', agreement.id);
          formik.current.setFieldValue(name + 'agreed', false);
        }
      }

      setAgreements(agreements);
    } catch (e) {
      console.error(e);
    }
  }, [formik]);

  useEffect(() => {
    if (!didMount) fetchAgreements();
  }, [fetchAgreements, didMount]);

  const getDriverId = () => {
    const urlParams = window.location.search;
    const driverId = urlParams.replace('?referrer=', '');

    return driverId;
  };

  useEffect(() => {
    if (authorized) {
      navigate('/deliverpro/ship');
    }
  }, [authorized, navigate]);

  const initialValues: DPSignupValues = {
    email: '',
    password: '',
    company: '',
    phone: '',
    first_name: '',
    last_name: '',
    agreements: initialAgreementsValues(agreements),
    commercial: true,
    referring_driver_id: '',
    platforms: ['deliver_pro'],
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      innerRef={formik}
      validateSchema={signupSchema}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <div>
            <ErrorCallout message={error} level='danger' />
            <Form className='u-push__top--lg'>
              <div className='sameRow'>
                <FormGroup
                  label='FIRST NAME *'
                  labelFor='first_name'
                  className='whiteLabel'
                >
                  <Field type='hidden' name='referrer' />
                  <Field type='text' name='first_name' as={InputGroup} />
                  <FieldError name='first_name' />
                </FormGroup>
                <FormGroup
                  label='LAST NAME *'
                  labelFor='last_name'
                  className='whiteLabel'
                >
                  <Field type='text' name='last_name' as={InputGroup} />
                  <FieldError name='last_name' />
                </FormGroup>
              </div>
              <div>
                <FormGroup
                  label='EMAIL *'
                  labelFor='email'
                  className='whiteLabel'
                >
                  <Field
                    type='email'
                    name='email'
                    className='bp4-input-group-icon'
                    as={InputGroup}
                  />
                  <FieldError name='email' />
                </FormGroup>
                <div className='sameRow'>
                  <FormGroup
                    label='PHONE *'
                    labelFor='phone'
                    className='whiteLabel'
                  >
                    <Field
                      type='phone'
                      name='phone'
                      className='bp4-input-group-icon'
                      as={InputGroup}
                    />
                    <FieldError name='phone' />
                  </FormGroup>
                  <FormGroup
                    label='PASSWORD *'
                    labelFor='password'
                    className='whiteLabel'
                  >
                    <Field
                      type='password'
                      name='password'
                      className='bp4-input-group-icon'
                      as={InputGroup}
                    />
                    <FieldError name='password' />
                  </FormGroup>
                  {errors.password && touched.password && errors.password}
                </div>
              </div>
              <div>
                <FormGroup
                  labelFor='company'
                  className='whiteLabel'
                  label='COMPANY NAME *'
                >
                  <Field type='text' name='company' as={InputGroup} />
                </FormGroup>
                {agreements.map((agreement, index) => (
                  <AgreementInput
                    key={agreement.id}
                    agreement={agreement}
                    index={index}
                    className='agreement-dark'
                  />
                ))}
              </div>
              <div>
                <FormGroup
                  label={'How did you hear of us? '}
                  labelFor='referrer'
                >
                  <Field
                    as={HTMLSelect}
                    id='referrer'
                    name='referrer'
                    options={referrerTypes}
                    className='select'
                  />
                </FormGroup>
              </div>
              <Button
                text='Sign Up'
                large
                fill
                id='login-button'
                className='primaryButtonFilled registrationAction u-push__top--sm'
                type='submit'
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={() =>
                  setFieldValue('referring_driver_id', getDriverId())
                }
              />
            </Form>
            <div className='registerButton'>
              <p className='registerLabel'>Already have a shipper account?</p>
              <Button
                text='Login'
                large
                fill
                id='show-login'
                className='whiteButton'
                onClick={() => {}}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
