import {
  faCalendar,
  faClock,
  faMapMarker,
} from '@fortawesome/pro-solid-svg-icons';
import { Match, MatchStop } from '../../lib/actions/MatchAction';
import {
  PublicMatch,
  PublicMatchStop,
} from '../../lib/actions/MatchStopAction';
import { dateWithTZ, formatDateSuccinct } from '../../lib/Utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  match: PublicMatch | Match;
  stop?: PublicMatchStop | MatchStop;
};

function renderPendingSLA(
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  timezone: string | null
) {
  const startDateWithTZ = dateWithTZ(startDate, timezone);
  const endDateWithTZ = dateWithTZ(endDate, timezone);
  const date = startDateWithTZ.calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    sameElse: 'MMM D, YYYY',
  });

  return (
    <p
      className={`u-push__bottom--sm u-push__top--md slaContainer slaContainer--pending`}
    >
      <span className={`slaETA slaETA--pending`}>ETA</span>
      <div className='slaDetails slaDetails--pending'>
        <FontAwesomeIcon icon={faCalendar} />
        <span className='u-text--size-1 u-pad__left--xs'>{date}</span>
        <br />
        <FontAwesomeIcon icon={faClock} />
        <span className='u-text--size-1 u-pad__left--xs'>
          {startDateWithTZ.format('h:mm A')} - {endDateWithTZ.format('h:mm A')}
        </span>
        <span className='u-text--size-0'> ({endDateWithTZ.format('z')})</span>
      </div>
    </p>
  );
}

function renderActiveSLA(
  arriveAt: string | null | undefined,
  timezone: string | null,
  distance: number | null
) {
  return (
    <p
      className={`u-push__bottom--sm u-push__top--md slaContainer slaContainer--active`}
    >
      <span className={`slaETA slaETA--active`}>ETA</span>
      <div className='slaDetails slaDetails--active'>
        {arriveAt && (
          <>
            <FontAwesomeIcon icon={faClock} />{' '}
            {dateWithTZ(arriveAt, timezone).fromNow(true)}
            <span className='u-pad__left--xs u-text--size-1'>
              <FontAwesomeIcon icon={faMapMarker} /> {distance} mi
            </span>
          </>
        )}
      </div>
    </p>
  );
}

function renderCompletedSLA(date: string | null, timezone: string | null) {
  return (
    <p
      className={`u-push__bottom--sm u-push__top--md slaContainer slaContainer--completed`}
    >
      <span className={`slaETA slaETA--completed`}>Completed</span>
      <div className='slaDetails slaDetails--completed'>
        <FontAwesomeIcon icon={faCalendar} />
        <span className='u-text--size-1 u-pad__left--xs'>
          {formatDateSuccinct(date, timezone)}{' '}
        </span>
        <br />
        <FontAwesomeIcon icon={faClock} />
        <span className='u-text--size-1 u-pad__left--xs'>
          {dateWithTZ(date, timezone).format('h:mm A')}
        </span>
        <span className='u-text--size-0'>
          {' '}
          ({dateWithTZ(date, timezone).format('z')})
        </span>
      </div>
    </p>
  );
}

function SLAContent({ match, stop }: Props) {
  const { timezone, eta, slas } = match;
  const fraytPickupSLA = slas.find(sla => sla.type === 'pickup');
  const fraytDeliverySLA = slas.find(sla => sla.type === 'delivery');

  console.log(eta);

  if (!stop) {
    // pickup
    switch (match.state) {
      case 'canceled':
        return renderCompletedSLA(match.canceled_at, timezone);
      case 'inactive':
      case 'pending':
      case 'scheduled':
      case 'assigning_driver':
      case 'offered':
      case 'offer_not_accepted':
      case 'accepted':
        return renderPendingSLA(
          eta?.window_start ?? fraytPickupSLA?.start_time,
          eta?.window_end ?? fraytPickupSLA?.end_time,
          timezone
        );
      case 'en_route_to_return':
      case 'arrived_at_return':
        return null;
      case 'en_route_to_pickup':
      case 'arrived_at_pickup':
        return renderActiveSLA(eta?.arrive_at, timezone, eta?.distance ?? null);
      case 'picked_up':
        return renderCompletedSLA(match.picked_up_at, timezone);
      case 'completed':
      case 'charged':
      case 'unable_to_pickup':
        return renderCompletedSLA(match.completed_at, timezone);
      default:
        return null;
    }
  } else {
    // dropoff
    switch (stop.state) {
      case 'unserved':
      case 'pending':
        return renderPendingSLA(
          stop.eta?.window_start ?? fraytDeliverySLA?.start_time,
          stop.eta?.window_end ?? fraytDeliverySLA?.end_time,
          timezone
        );
      case 'en_route':
      case 'arrived':
      case 'signed':
        return renderActiveSLA(
          stop.eta?.arrive_at,
          timezone,
          stop.eta?.distance ?? null
        );
      case 'delivered':
      case 'returned':
        return renderCompletedSLA(stop.delivered_at, timezone);
      case 'undeliverable':
      default:
        return null;
    }
  }
}

export default function SLARange(props: Props) {
  const stop = props.stop;
  const match = props.match;

  return <SLAContent match={match} stop={stop} />;
}
