import { Field, useField } from 'formik';
import { FormGroup, HTMLSelect } from '@blueprintjs/core';
import { SignatureType } from '../../lib/actions/MatchAction';

const signatureTypes: { label: string; value: SignatureType }[] = [
  { value: SignatureType.Electronic, label: 'Electronic Signature' },
  { value: SignatureType.Photo, label: 'Photo of Bill of Lading' },
  {
    value: SignatureType.ElectronicBillOfLading,
    label: 'FRAYT Generated Electronic Bill of Lading',
  },
];

export const SignatureTypeSelect = ({ name }: { name: string }) => {
  const [{ value }] = useField<string>(`${name}`);

  return (
    <FormGroup label='Signature Type' labelFor={name}>
      <Field
        as={HTMLSelect}
        name={name}
        options={signatureTypes}
        value={value}
        className='select'
      />
      {value === SignatureType.Photo && (
        <p className='u-push__top--sm'>
          A photo of the bill of lading will be required on both pickup and
          dropoff.
        </p>
      )}

      {value === SignatureType.ElectronicBillOfLading && (
        <p className='u-push__top--sm'>
          A FRAYT generated electronic Bill of Lading requires signatures at
          pickup and drop-off and becomes available after the drop-off.
        </p>
      )}
    </FormGroup>
  );
};
