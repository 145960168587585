import { Marker } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import { PublicMatchStop } from '../../lib/actions/MatchStopAction';
import Map from '../Map';

export type MatchStopMapProps = {
  matchStop: PublicMatchStop;
};

export default function MatchStopMap({ matchStop }: MatchStopMapProps) {
  const {
    match: { origin_address },
    destination_address,
  } = matchStop;
  const [map, setMap] = useState<google.maps.Map>();
  const [origin_latlng] = useState<google.maps.LatLng>(
    new google.maps.LatLng(origin_address.lat, origin_address.lng)
  );
  const [destination_latlng] = useState<google.maps.LatLng>(
    new google.maps.LatLng(destination_address.lat, destination_address.lng)
  );

  const setBounds = useCallback(() => {
    if (map) {
      const bounds = new google.maps.LatLngBounds(origin_latlng);
      const destination_bounds = new google.maps.LatLngBounds(
        destination_latlng
      );

      bounds.union(destination_bounds);

      map.fitBounds(bounds);
    }
  }, [origin_latlng, destination_latlng, map]);

  useEffect(() => {
    setBounds();
  }, [setBounds]);

  const getMarkers = () => {
    const markers = [] as React.ReactNode[];

    markers.push(
      <Marker position={origin_latlng} label={{ text: 'P', color: '#fff' }} />
    );

    markers.push(
      <Marker
        position={destination_latlng}
        label={{ text: 'D', color: '#fff' }}
      />
    );

    return markers;
  };

  return <Map onLoad={setMap} directions={null} markers={getMarkers()} />;
}
