import { Grid, Row, Col } from 'react-flexbox-grid';
import DeliverProSignUp from '../components/user/auth/DeliverProSignUp';

export default function DeliverProSignUpScreen() {
  return (
    <div className='appContent login'>
      <Grid className='login-fullheight dpSignUp'>
        <Row className='login-centered'>
          <Col xs={0} md={3} lg={4} className='unusedMobileColumn'></Col>
          <Col xs={12} md={6} lg={4} className='leftColumn'>
            <DeliverProSignUp />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
