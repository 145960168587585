import { Address } from '../../lib/actions/UserAction';
import { formatAddress } from '../../lib/Utility';

type Props = {
  address: Address;
};

export default function AddressSummary({ address }: Props) {
  return (
    <div>
      {address.name && <h2>{address.name}</h2>}
      <h2>{formatAddress(address.formatted_address)}</h2>
    </div>
  );
}
