import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import * as MatchStopAction from '../actions/MatchStopAction';
import {
  PublicMatchStop,
  PublicMatchStopResponseData,
} from '../actions/MatchStopAction';
import { createAxiosThunk, ThunkStatus } from '../AxiosThunk';

export type PublicMatchStopState = {
  matchStop: PublicMatchStop | null;
  status: ThunkStatus;
  error?: null | string;
};

const initialState: PublicMatchStopState = {
  matchStop: null,
  status: 'loading',
  error: null,
};

export const trackMatchStop = createAxiosThunk<
  PublicMatchStopResponseData,
  { matchStopId: string; token: string | null }
>('matchStop/track', args =>
  MatchStopAction.getMatchStop(args.matchStopId, args.token)
);

const matchStopSuccess = (
  state: PublicMatchStopState,
  action: PayloadAction<PublicMatchStopResponseData, string>
) => {
  const response = action.payload.response;

  state.matchStop = response;
  state.status = 'succeeded';
  state.error = null;
};

const matchStopSlice = createSlice({
  name: 'matchStop',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(trackMatchStop.fulfilled, matchStopSuccess);
  },
});

export default matchStopSlice.reducer;

export const selectMatchStop = (state: RootState) => state.matchStop.matchStop;
