import {
  AnchorButton,
  Button,
  Callout,
  FormGroup,
  InputGroup,
} from '@blueprintjs/core';
import { Field, Formik, yupToFormErrors } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Match, MatchData } from '../../lib/actions/MatchAction';
import {
  authorizeEstimate,
  duplicateMatch,
  selectEstimateMatch,
  selectEstimateErrors,
  selectEstimateStatus,
} from '../../lib/reducers/estimateSlice';
import { useAppDispatch } from '../../lib/store';
import EstimateBanner from './EstimateBanner';
import MatchScheduler, {
  matchScheduleSchema,
  ScheduleValues,
} from './MatchScheduler';
import { fetchUserContracts, selectUser } from '../../lib/reducers/userSlice';

type DuplicateMatchFormProps = {
  match: Match;
  onDone: () => void;
};

type DuplicateMatchValues = ScheduleValues &
  PickRequired<MatchData, 'po' | 'coupon_code'>;

export default function DuplicateMatchForm({
  match,
  onDone,
}: DuplicateMatchFormProps) {
  const user = useSelector(selectUser);
  const estimate = useSelector(selectEstimateMatch);
  const error = useSelector(selectEstimateErrors);
  const status = useSelector(selectEstimateStatus);
  const dispatch = useAppDispatch();
  const [initialValues, setInitialValues] = useState<DuplicateMatchValues>({
    vehicle_class_id: match.vehicle_class.id,
    po: match.po,
    pickup_at: null,
    dropoff_at: null,
    coupon_code: null,
    scheduled: false,
    timezone: moment.tz.guess(),
  });
  const navigate = useNavigate();
  const getEstimate = (values: DuplicateMatchValues) => {
    dispatch(duplicateMatch([match.id, values])).then(() => {
      setInitialValues(values);
    });
  };

  const authorize = () => {
    if (estimate) {
      dispatch(authorizeEstimate(estimate.id)).then(() => {
        onDone();
        const tab = window.open(`/matches/${estimate.id}`, '_blank');

        if (tab) {
          tab.focus();
        } else {
          navigate(`/matches/${estimate.id}`);
        }
      });
    }
  };

  const validate = (values: DuplicateMatchValues) => {
    try {
      matchScheduleSchema.validateSync(values, {
        abortEarly: false,
        context: values,
      });
    } catch (e) {
      return yupToFormErrors(e); //for rendering validation errors
    }
  };

  useEffect(() => {
    dispatch(fetchUserContracts());
  }, [dispatch]);

  return (
    <div className='DuplicateMatch__form'>
      <Formik<DuplicateMatchValues>
        initialValues={initialValues}
        onSubmit={getEstimate}
        enableReinitialize={true}
        validate={validate}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <FormGroup label='PO' labelFor='po'>
              <Field as={InputGroup} name='po' />
            </FormGroup>

            <div className='panelDivider' />

            <MatchScheduler />

            <div className='panelDivider' />

            <FormGroup label='Coupon' labelFor='coupon_code'>
              <Field as={InputGroup} name='coupon_code' />
            </FormGroup>

            <div className='panelDivider' />

            {error.message && (
              <Callout
                intent={error.level}
                title={'There was an error.'}
                style={{ marginBottom: 20 }}
              >
                {error.message}
              </Callout>
            )}

            <EstimateBanner />

            <Button type='button' className='warningButton' onClick={onDone}>
              Cancel
            </Button>

            {!estimate || formik.dirty ? (
              <Button
                type='submit'
                className='secondaryButtonFilled'
                style={{ float: 'right' }}
                disabled={
                  status === 'loading' || !formik.isValid || !formik.dirty
                }
              >
                Get Estimate
              </Button>
            ) : (
              <>
                <Button
                  type='button'
                  className='secondaryButtonFilled'
                  style={{ float: 'right' }}
                  onClick={() => authorize()}
                  disabled={status === 'loading'}
                >
                  Authorize Match
                </Button>
                <AnchorButton
                  className='secondaryButton'
                  style={{ float: 'right', marginRight: 10 }}
                  href={`/ship/${estimate.id}`}
                  target='_blank'
                >
                  {/* Easter egg for Clearjet */}
                  {user?.id === '718ea064-6ec8-4494-848b-6112c478405d'
                    ? 'Edit More (J)'
                    : 'Edit More'}
                </AnchorButton>
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
}
