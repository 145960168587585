import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { displayPrice } from '../../lib/Utility';
import { Fee, Match } from '../../lib/actions/MatchAction';

type DiscountViewProps = {
  match: Match;
};

function DiscountView({
  match: { coupon, price_discount },
}: DiscountViewProps) {
  if (coupon && price_discount) {
    return (
      <div>
        <b>Discount:</b> -{displayPrice(price_discount, false)} (
        {coupon.percentage}% off)
      </div>
    );
  }

  return null;
}

export function renderPricing(
  amount: number,
  original_amount: number | undefined,
  description: string | null,
  classes = ''
) {
  if (original_amount && amount !== undefined) {
    return (
      <span>
        <span className={classes} style={{ textDecoration: 'line-through' }}>
          {displayPrice(original_amount)}
        </span>
        <span className={classes} style={{ color: '#00CD4D' }}>
          {' '}
          {displayPrice(amount)}
        </span>
      </span>
    );
  } else {
    return (
      <span className={classes}>
        {displayPrice(original_amount ? original_amount : amount)}
        {description && <i> – {description}</i>}{' '}
      </span>
    );
  }
}

type FeeViewProps = {
  fee: Fee;
};

function FeeView({
  fee: { name, amount, original_amount, description, type },
}: FeeViewProps) {
  let info = null;
  switch (type) {
    case 'route_surcharge':
      info =
        "This charge covers Frayt's cost to calculate and generate a multistop route.";
      break;
    case 'toll_fees':
      info =
        'This charge is the total amount of tolls the driver will encounter on your delivery.';
      break;
  }

  if (amount || original_amount) {
    return (
      <div>
        <b>{name}: </b>
        {renderPricing(amount, original_amount, description)}
        {info && (
          <span className='u-display__inline-block'>
            <FontAwesomeIcon icon={faInfoCircle} data-tip={info} />
            <ReactTooltip place='right' type='info' effect='solid' />
          </span>
        )}
      </div>
    );
  }

  return null;
}

type PriceBreakdownProps = {
  match: Match;
};

export function PriceBreakdown({ match }: PriceBreakdownProps) {
  return (
    <div>
      {match.contract && (
        <>
          <b>Contract:</b> {match.contract.name}
        </>
      )}
      <hr />
      {match.fees.map((fee, index) => (
        <FeeView key={index} fee={fee} />
      ))}
      <DiscountView match={match} />
      <hr />
      <b>TOTAL:</b> {displayPrice(match.total_price, false)}
      <hr />
    </div>
  );
}
