import { Button, Callout, InputGroup, Menu, MenuItem } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { Driver, Match, VehicleClass } from '../../../lib/actions/MatchAction';
import { getPreferredDriverOptionsBy } from '../../../lib/actions/UserAction';
import { useEffect, useState } from 'react';
import { useDebouncer } from '../../../lib/Hooks';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../lib/reducers/userSlice';
import { selectEstimateMatch } from '../../../lib/reducers/estimateSlice';
import { handleDriverSelectSubmit } from '../../../lib/Utility';
import { DeliverProBadge } from '../../deliverPro/DeliverProBadge';
import VehicleName from './DriverName';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type SelectByNameValues = { name?: string };
const selectByNameSchema: ObjectSchema<SelectByNameValues> = yup
  .object()
  .shape({
    name: yup.string().optional(),
  });

type PreferredDriverSelectByNameProps = {
  minVehicleClass?: VehicleClass;
  currentPreferredDriver?: Driver | null;
  onSelect?: (driver: Driver | undefined) => void;
  padding?: boolean;
  currentMatch?: Match;
  defaultDriverId?: string | null | undefined;
  confirmDefaultDriver?: (defaultDriver: Driver) => void;
};

function setDefaultDriver(driver: Driver, setter?: (driver: Driver) => void) {
  return setter ? (
    <div
      className='defaultDriverButton'
      onClick={() => {
        setter(driver);
      }}
    >
      Make Default
    </div>
  ) : (
    <></>
  );
}

export default function PreferredDriverSelectByName({
  onSelect,
  padding = true,
  currentMatch,
  minVehicleClass,
  defaultDriverId,
  confirmDefaultDriver,
}: PreferredDriverSelectByNameProps) {
  const [showDriverOption, setShowDriverOption] = useState(false);

  const [suggestedDrivers, setSuggestedDrivers] = useState<Driver[]>();

  const [recentDrivers, setDriverOption] = useState<Driver[]>([]);

  const [debounce] = useDebouncer(1000);

  const shipper = useSelector(selectUser);
  const matchFromState = useSelector(selectEstimateMatch);

  const match = currentMatch
    ? currentMatch
    : matchFromState !== null
    ? matchFromState
    : undefined;

  useEffect(() => {
    if (shipper && shipper.id && match && match.id) {
      getPreferredDriverOptionsBy({
        type: 'suggested_for_match',
        query: match.id,
      }).then(response => {
        setSuggestedDrivers(response.data.data);
      });
    }
  }, [shipper, match]);

  const handleSubmit = async (
    values: SelectByNameValues,
    { setFieldError }: FormikHelpers<SelectByNameValues>
  ) => {
    const { name } = values;
    const submitResult = await handleDriverSelectSubmit(
      'name',
      name,
      setFieldError,
      match
    );
    if (submitResult !== undefined) {
      setDriverOption(submitResult);
      setShowDriverOption(true);
    } else {
      setShowDriverOption(false);
    }
  };
  const renderSuggestedDrivers = () => {
    if (suggestedDrivers && suggestedDrivers.length > 0)
      return suggestedDrivers.map(driver => (
        <MenuItem
          className='suggestedDriver'
          key={driver.id}
          text={`${driver.first_name} ${driver.last_name}`}
          labelElement={
            <div className='driverLabels'>
              <DeliverProBadge driver={driver} />
              <VehicleName vehicle={driver.vehicle} />
              <div
                style={{
                  display: 'inline-block',
                  backgroundColor: '#0066ff',
                  borderRadius: '20px',
                  padding: '0px 10px',
                  margin: '0px 0px 0px 0.25rem',
                }}
              >
                <text style={{ color: 'white' }}>SUGGESTED</text>
              </div>
            </div>
          }
          onClick={() => {
            setShowDriverOption(false);
            if (onSelect) onSelect(driver);
          }}
        />
      ));
  };

  const renderDrivers = () => {
    return (
      <Menu
        style={{
          width: '100%',
          maxHeight: '200px',
          overflowY: 'auto',
          position: 'relative',
          boxShadow: '0px 0px 5px grey',
        }}
      >
        {renderSuggestedDrivers()}
        {recentDrivers &&
          recentDrivers.map(driver => {
            const disabled =
              minVehicleClass != null &&
              (driver.vehicle.vehicle_class.type.index <
                minVehicleClass.type.index ||
                driver.vehicle.vehicle_class.index < minVehicleClass.index);

            return (
              <div className='driverSelectContainer' key={driver.id}>
                <MenuItem
                  style={{ width: '100%' }}
                  disabled={disabled}
                  text={`${driver.first_name} ${driver.last_name}`}
                  onClick={() => {
                    setShowDriverOption(false);
                    if (onSelect) onSelect(driver);
                  }}
                  labelElement={
                    <div className='driverLabels'>
                      <DeliverProBadge driver={driver} />
                      <VehicleName vehicle={driver.vehicle} />
                    </div>
                  }
                ></MenuItem>

                <div style={{ color: '#616B7A' }}>
                  {driver.id === defaultDriverId ? (
                    <>
                      <FontAwesomeIcon icon={faStar} /> Default
                    </>
                  ) : (
                    setDefaultDriver(driver, confirmDefaultDriver)
                  )}
                </div>
              </div>
            );
          })}
      </Menu>
    );
  };

  return (
    <Col xs={12} className={padding ? 'u-push__top--lg' : ''}>
      <Row>
        <Col xs={12}>
          <Formik
            validateOnMount
            initialValues={{ name: '' } as SelectByNameValues}
            validationSchema={selectByNameSchema}
            onSubmit={handleSubmit}
          >
            {({ submitForm, handleChange, errors, handleSubmit }) => (
              <>
                <InputGroup
                  placeholder='Driver Name'
                  onClick={() => setShowDriverOption(true)}
                  name='name'
                  fill={true}
                  asyncControl={true}
                  onChange={e => {
                    handleChange(e);
                    debounce(handleSubmit);
                  }}
                  rightElement={
                    <Button
                      className='selectByNameButton'
                      icon='search'
                      minimal={true}
                      onClick={() => submitForm()}
                    />
                  }
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      submitForm();
                    }
                  }}
                />
                {showDriverOption && (
                  <div className='selectByNamePopover'>{renderDrivers()}</div>
                )}
                {errors.name && (
                  <Callout
                    className='preferredDriverSelectCallout'
                    intent='danger'
                    style={{ marginTop: '15px' }}
                  >
                    {errors.name}
                  </Callout>
                )}
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Col>
  );
}
