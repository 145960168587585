import Axios, { AxiosResponse } from 'axios';
import { buildHeaders, buildUrl } from '../FraytRequest';

export type Accessorial = {
  id: string;
  key: string;
  name: string;
  description: string;
};

export type AccessorialsResponseData = { response: Accessorial[] };

export type AccessorialsResponse = AxiosResponse<AccessorialsResponseData>;

export async function getAccessorials(): Promise<AccessorialsResponse> {
  const headers = buildHeaders();
  const url = buildUrl(`accessorials`);

  return Axios.get(url, { headers });
}
