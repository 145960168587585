import { useNavigate } from 'react-router-dom';
import 'moment-timezone';
import StepFooter from '../StepFooter';
import { ShipTabKey, ShipTabProps } from '../../../screens/ShipScreen';
import { useSelector } from 'react-redux';
import {
  authorizeEstimate,
  selectEstimateErrors,
  selectEstimateMatch,
  setPreferredDriverType,
  updateEstimate,
} from '../../../lib/reducers/estimateSlice';
import { useAppDispatch } from '../../../lib/store';
import { Col, Row } from 'react-flexbox-grid';
import { Label } from '@blueprintjs/core';
import { PlacesSearch } from '../../form/PlacesSearch';
import FieldError from '../../form/FieldError';
import { Form, Formik, yupToFormErrors } from 'formik';
import EstimateFormOnChange from '../EstimateFormOnChange';
import { useCallback, useState } from 'react';
import {
  EstimateValues,
  buildEstimateStopValues,
  buildEstimateValues,
  reviewSchema,
} from './Estimate';
import { FormikChangeHelpers } from '../../form/FormikOnChange';
import { Match, MatchStop } from '../../../lib/actions/MatchAction';
import moment from 'moment-timezone';
import PaymentSummary from '../../estimate/summary/PaymentSummary';
import EstimateSummary from '../summary/EstimateSummary';
import DeliverySummary from '../summary/DeliverySummary';
import CargoSummary from '../summary/CargoSummary';
import { renderPricing } from '../PriceBreakdown';
export default function Review({ changeTab }: ShipTabProps) {
  const initialOpen = window.innerWidth > 767;
  const match: Match | null = useSelector(selectEstimateMatch);
  const estimateError = useSelector(selectEstimateErrors);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const complete_origin_address = match
    ? match?.origin_address?.type === 'address'
    : false;
  const complete_destination_addresses =
    match?.stops?.map(
      (stop: MatchStop) => stop?.destination_address?.type === 'address'
    ) || [];

  const incomplete_dest_addresses = match?.stops.filter(
    (s, index) => !complete_destination_addresses[index]
  );

  const [initialValues] = useState<EstimateValues>(
    buildEstimateValues(match, {
      origin_address: complete_origin_address
        ? match?.origin_address?.formatted_address
        : '',
      stops: match?.stops.map((stop: MatchStop, index: number) =>
        complete_destination_addresses[index]
          ? buildEstimateStopValues(stop)
          : buildEstimateStopValues(stop, { destination_address: '' })
      ),
    })
  );
  const any_incomplete_dest_addresses = !!incomplete_dest_addresses?.length;

  const handleNext = () => {
    if (match) {
      dispatch(authorizeEstimate(match.id))
        .unwrap()
        .then(() => {
          sessionStorage.removeItem('weightInfo');
          dispatch(setPreferredDriverType(0));
          navigate('/matches/' + match.id);
        });
    }
  };
  const showSideBar = false;
  const handleSubmit = async (
    values: EstimateValues,
    actions: FormikChangeHelpers<EstimateValues>
  ) => {
    if (match) {
      dispatch(updateEstimate([match.id, values])).then(() => {
        actions.setSubmitting(false);
      });
    }
  };
  const validate = useCallback((values: EstimateValues) => {
    try {
      reviewSchema.validateSync(values, {
        abortEarly: false,
        context: values,
      });
    } catch (e) {
      return yupToFormErrors(e); // for rendering validation errors
    }
  }, []);

  const pickupLocation = match?.origin_address?.formatted_address;
  const momentDate = moment(match?.inserted_at);
  const orderDate = momentDate.format('MMM D, YYYY');

  return (
    <div>
      <h1 className='u-push__bottom--md u-push__top--none'>Review Match</h1>
      {!complete_origin_address || any_incomplete_dest_addresses ? (
        <p className='u-push__bottom--lg addresses_message'>
          You will now need to enter any incomplete addresses. Your original
          estimate used partial addresses for{' '}
          {!complete_origin_address && (
            <>
              <span>{pickupLocation} </span>
              (Pickup)
            </>
          )}{' '}
          {!complete_origin_address && any_incomplete_dest_addresses && 'and '}
          {incomplete_dest_addresses?.map((stop: MatchStop) => {
            return (
              <>
                <span>{stop?.destination_address?.formatted_address}</span>{' '}
                (Stop #{stop.index + 1}){' '}
              </>
            );
          })}
        </p>
      ) : (
        <p className='u-push__bottom--lg addresses_message'>
          We now have everything we need, so review your details for accuracy
          then click the Ship button at the bottom of this page to place your
          order once you have confirmed the final estimate
        </p>
      )}
      <br />
      <div>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleNext}
        >
          {({ submitForm }) => (
            <Form>
              <EstimateFormOnChange
                onChange={(values: EstimateValues, _meta, actions) =>
                  handleSubmit(values, actions)
                }
              />
              <div>
                {(!complete_origin_address ||
                  any_incomplete_dest_addresses) && (
                  <Row>
                    <Col xs={12} md={showSideBar ? 12 : 6}>
                      <Label>
                        PICKUP
                        <PlacesSearch
                          name='origin_address'
                          placeName='origin_place_id'
                          icon='arrow-up'
                          requireFullAddress={true}
                        />
                        <FieldError name='origin_address' />
                      </Label>
                    </Col>
                    <Col xs={12} md={showSideBar ? 12 : 6}>
                      {incomplete_dest_addresses?.map((stop: MatchStop) => {
                        const name = `stops[${stop.index}]`;
                        return (
                          <Col key={stop?.id}>
                            <Label>
                              {match?.stops?.length && match?.stops?.length > 1
                                ? `STOP #${stop.index + 1}`
                                : 'DROPOFF'}
                              <PlacesSearch
                                name={`${name}.destination_address`}
                                placeName={`${name}.destination_place_id`}
                                icon='arrow-down'
                                requireFullAddress={true}
                              />
                              <p>
                                Original:{' '}
                                {stop.destination_address.formatted_address}
                              </p>
                              <FieldError
                                name={`${name}.destination_address`}
                              />
                            </Label>
                          </Col>
                        );
                      })}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} md={showSideBar ? 12 : 6}>
                    <div className='estimate_card'>
                      <div className='card_header'>
                        <div className='header_cell'>
                          <span className='header_first'>Order Date</span>
                          <span className='header_second'>{orderDate}</span>
                        </div>
                        <div className='header_cell'>
                          <span className='header_first'>Match ID</span>
                          <span className='header_second'>
                            {match?.shortcode}
                          </span>
                        </div>
                        <div className='header_cell'>
                          <span className='header_first'>Vehicle</span>
                          <span className='header_second'>
                            {match?.vehicle_class.type.name}
                          </span>
                        </div>
                      </div>
                      <div className='divider' />
                      {match?.fees?.map(
                        (
                          item: {
                            id: string;
                            name: string | number | boolean;
                            amount: number;
                            original_amount?: number;
                          },
                          index: number
                        ) => {
                          return (
                            <>
                              <div key={item.id} className='card_header'>
                                <div className='header_cell'>
                                  <span className='header_second'>
                                    {item?.name}
                                  </span>
                                </div>
                                <div className='header_cell'>
                                  {renderPricing(
                                    item.amount,
                                    item.original_amount,
                                    '',
                                    'header_second'
                                  )}
                                </div>
                              </div>
                              {match?.fees?.length - 1 !== index && (
                                <div className='divider' />
                              )}
                            </>
                          );
                        }
                      )}
                      <div className='card_bottom card_header'>
                        <div className='header_cell'>
                          <span className='bottom_cell_label'>Total</span>
                        </div>
                        <div className='header_cell'>
                          <span className='bottom_cell_label'>
                            ${match?.total_price}
                          </span>
                        </div>
                      </div>
                    </div>
                    <PaymentSummary
                      initialIsOpen={initialOpen}
                      onReview={() => changeTab(ShipTabKey.Payment)}
                    />
                  </Col>
                  <Col xs={12} md={showSideBar ? 12 : 6}>
                    {match && (
                      <EstimateSummary
                        match={match}
                        initialIsOpen={initialOpen}
                        onReview={() => changeTab(ShipTabKey.Estimate)}
                        logistics={true}
                      />
                    )}
                    {match && (
                      <DeliverySummary
                        match={match}
                        initialIsOpen={initialOpen}
                        onReview={() => changeTab(ShipTabKey.Delivery)}
                      />
                    )}
                    {match && (
                      <CargoSummary
                        match={match}
                        initialIsOpen={initialOpen}
                        onReview={() => changeTab(ShipTabKey.Cargo)}
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <StepFooter
                onPrev={() => changeTab(-1)}
                onNext={submitForm}
                nextLabel='Authorize'
                isForm={false}
                error={estimateError.message}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
