import { Button, FormGroup, HTMLSelect, InputGroup } from '@blueprintjs/core';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Agreement,
  getShipperReferral,
  getUserAgreements,
  NewUserData,
} from '../../../lib/actions/UserAction';
import { useDidMount } from '../../../lib/Hooks';
import { registerUser } from '../../../lib/reducers/userSlice';
import { useAppDispatch } from '../../../lib/store';
import AgreementInput, {
  agreementsSchema,
  initialAgreementsValues,
} from './AgreementInput';
import * as yup from 'yup';
import { passwordType } from '../../form/PasswordInput';
import FieldError from '../../form/FieldError';
import { useSearchParams } from 'react-router-dom';
import { ReferrerType } from '../../../lib/actions/MatchAction';

type RegistrationValues = Pick<
  NewUserData,
  | 'first_name'
  | 'last_name'
  | 'phone'
  | 'company'
  | 'agreements'
  | 'email'
  | 'password'
  | 'commercial'
  | 'referring_driver_id'
  | 'referrer'
>;

const registrationSchema: yup.ObjectSchema<RegistrationValues> =
  agreementsSchema.shape({
    commercial: yup
      .boolean()
      .required()
      .isTrue('Only commercial shippers are allowed'),
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    phone: yup.string().required('Phone number is required'),
    company: yup.string().required('Company name is required'),
    referrer: yup
      .string()
      .notOneOf(['select_referrer'], 'Referrer name is required')
      .required('Referrer name is required'),
    email: yup.string().required('Email is required').email('Email is invalid'),
    password: passwordType,
    referring_driver_id: yup.string().optional(),
  });

type RegistrationFormProps = {
  shipScreen: boolean;
};

const referrerTypes: { label: string; value: ReferrerType }[] = [
  { value: ReferrerType.Select_Referrer, label: 'Select Referrer' },
  { value: ReferrerType.None_Other, label: 'None/Other' },
  { value: ReferrerType.LinkedIn, label: 'LinkedIn' },
  { value: ReferrerType.Google, label: 'Google' },
  { value: ReferrerType.Advertising, label: 'Advertising' },
  { value: ReferrerType.PR, label: 'PR' },
  { value: ReferrerType.Search_Engine, label: 'Search Engine' },
  { value: ReferrerType.Email, label: 'Email' },
  { value: ReferrerType.Friend_Colleague, label: 'Friend Or Colleague' },
  { value: ReferrerType.Billboard, label: 'Billboard' },
  { value: ReferrerType.Driver, label: 'Driver' },
];

export default function RegistrationForm({
  shipScreen,
}: RegistrationFormProps) {
  const labelClass = shipScreen ? undefined : 'whiteLabel';
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const dispatch = useAppDispatch();
  const formik = React.createRef<FormikProps<RegistrationValues>>();
  const didMount = useDidMount();
  const [URLparams] = useSearchParams();
  const referringDriverId = URLparams.get('referrer') || undefined;

  const fetchShipperReferral = async (referral_id: string) => {
    try {
      const result = await getShipperReferral(referral_id);
      return result.data;
    } catch (e) {
      console.error(e);
    }
  };

  const setReferralData = useCallback(async () => {
    const referralId = URLparams.get('referral');
    if (referralId) {
      const referral = await fetchShipperReferral(referralId);
      if (referral) {
        formik.current?.setFieldValue('email', referral.email);
        formik.current?.setFieldValue(
          'referring_driver_id',
          referral.driver_id
        );
      }
    }
  }, [URLparams, formik]);

  const handleFormSubmit = (
    values: RegistrationValues,
    actions: FormikHelpers<RegistrationValues>
  ) => {
    dispatch(registerUser(values)).finally(() => actions.setSubmitting(false));
  };

  const fetchAgreements = useCallback(async () => {
    try {
      const result = await getUserAgreements();
      const { agreement_documents: agreements } = result.data;

      if (formik.current) {
        for (let index = 0; index < agreements.length; index++) {
          const agreement = agreements[index] as Agreement,
            name = `agreements[${index}]`;

          formik.current.setFieldValue(name + 'document_id', agreement.id);
          formik.current.setFieldValue(name + 'agreed', false);
        }
      }

      setAgreements(agreements);
    } catch (e) {
      console.error(e);
    }
  }, [formik]);

  useEffect(() => {
    if (!didMount) fetchAgreements();
  }, [fetchAgreements, didMount]);

  useEffect(() => {
    setReferralData();
  }, [setReferralData]);

  const initialValues: RegistrationValues = {
    email: '',
    password: '',
    company: '',
    referrer: '',
    phone: '',
    first_name: '',
    last_name: '',
    agreements: initialAgreementsValues(agreements),
    commercial: true,
    referring_driver_id: referringDriverId || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      innerRef={formik}
      validationSchema={registrationSchema}
    >
      {({ isSubmitting }) => {
        return (
          <Form className='u-push__top--lg'>
            <div className='sameRow'>
              <FormGroup
                label='FIRST NAME *'
                labelFor='first_name'
                className={labelClass}
              >
                <Field type='text' name='first_name' as={InputGroup} />
                <FieldError name='first_name' />
              </FormGroup>
              <FormGroup
                label='LAST NAME *'
                labelFor='last_name'
                className={labelClass}
              >
                <Field type='text' name='last_name' as={InputGroup} />
                <FieldError name='last_name' />
              </FormGroup>
            </div>
            <div>
              <FormGroup
                label='EMAIL *'
                labelFor='email'
                subLabel="Please enter your business email to register. If you are applying to be a driver, please use your phone and download the FRAYT driver APP on your device's App Store."
                className={labelClass}
              >
                <Field
                  type='email'
                  name='email'
                  className='bp4-input-group-icon'
                  leftIcon='envelope'
                  as={InputGroup}
                  readOnly={URLparams.get('referral')}
                />
                <FieldError name='email' />
              </FormGroup>
              <div className='sameRow'>
                <FormGroup
                  label='PHONE *'
                  labelFor='phone'
                  className={labelClass}
                >
                  <Field
                    type='phone'
                    name='phone'
                    className='bp4-input-group-icon'
                    leftIcon='phone'
                    as={InputGroup}
                  />
                  <FieldError name='phone' />
                </FormGroup>
                <FormGroup
                  label='PASSWORD *'
                  labelFor='password'
                  className={labelClass}
                >
                  <Field
                    type='password'
                    name='password'
                    className='bp4-input-group-icon'
                    leftIcon='lock'
                    as={InputGroup}
                  />
                  <FieldError name='password' />
                </FormGroup>
              </div>
            </div>
            <div>
              <FormGroup
                labelFor='company'
                className={labelClass}
                label='COMPANY NAME *'
              >
                <Field type='text' name='company' as={InputGroup} />
                <FieldError name='company' />
              </FormGroup>
            </div>
            <div>
              <FormGroup
                label={'HOW DID YOU HEAR OF US? '}
                labelFor='referrer'
                className={labelClass}
              >
                <Field
                  as={HTMLSelect}
                  id='referrer'
                  name='referrer'
                  options={referrerTypes}
                  className='select'
                />
                <FieldError name='referrer' />
              </FormGroup>
              {agreements.map((agreement, index) => (
                <AgreementInput
                  key={agreement.id}
                  agreement={agreement}
                  index={index}
                  className={shipScreen ? 'agreement-light' : 'agreement-dark'}
                />
              ))}
            </div>
            <Button
              text='Sign Up'
              large
              fill
              id='login-button'
              className='primaryButtonFilled registrationAction u-push__top--sm'
              type='submit'
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
