import { MatchState } from '../../../lib/actions/MatchAction';
import { PublicMatch } from '../../../lib/actions/MatchStopAction';
import {
  chunkArray,
  formatDateSuccinct,
  formatTime,
  matchInState,
} from '../../../lib/Utility';

type Props = {
  match: PublicMatch;
};

export default function MatchTimestampCards({ match }: Props) {
  const timestamps = matchInState(match, [
    MatchState.Canceled,
    MatchState.AdminCanceled,
  ])
    ? [{ title: 'CANCELED AT', timestamp: match.canceled_at }]
    : [
        { title: 'ACTIVATED AT', timestamp: match.activated_at },
        { title: 'ACCEPTED AT', timestamp: match.accepted_at },
        { title: 'PICKED UP AT', timestamp: match.picked_up_at },
        { title: 'COMPLETED AT', timestamp: match.completed_at },
      ];

  return (
    <>
      {chunkArray(timestamps, 2).map((timestamps, index) => (
        <div className='cardThreeColumn' key={index}>
          {timestamps.map(({ title, timestamp }, index) => (
            <div className='cardInset' key={index}>
              <div className='cardInset--data'>
                <p className='statusDescription'>{title}</p>
                {timestamp ? (
                  <>
                    <h2>{formatTime(timestamp, match.timezone)}</h2>
                    <p>{formatDateSuccinct(timestamp, match.timezone)}</p>
                  </>
                ) : (
                  <h2>-</h2>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
