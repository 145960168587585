import Axios, { AxiosResponse } from 'axios';
import { buildHeaders, buildUrl } from '../FraytRequest';
import { VehicleType } from './MatchAction';

export type VehicleTypeWithClasses = {
  classes: VehicleClass[];
} & VehicleType;

export type VehicleClass = {
  id: string;
  name: string;
  index: number;
  capacity_description: string;
  max_declared_value: number;
};

export type VehicleTypesResponseData = VehicleTypeWithClasses[];

export type VehicleTypesResponse = AxiosResponse<VehicleTypesResponseData>;

export async function getVehicleTypes(): Promise<VehicleTypesResponse> {
  const headers = buildHeaders();
  const url = buildUrl(`vehicle_types`);

  return Axios.get(url, { headers });
}
